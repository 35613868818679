export const SET_ACTIVITY_TIMER = 'SET_ACTIVITY_TIMER';
export const SET_SESSION_TIMER = 'SET_SESSION_TIMER';

export const setActivityTimer = (endTime, show) => ({
  type: SET_ACTIVITY_TIMER,
  payload: { endTime, show },
});

export const setSessionTimer = (endTime, show) => ({
  type: SET_SESSION_TIMER,
  payload: { endTime, show },
});
