// Action Types
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const MESSAGE_ERROR = 'MESSAGE_ERROR';
export const UPDATE_TYPING_STATUS = 'UPDATE_TYPING_STATUS';
export const ADD_MESSAGE_TO_QUEUE = 'ADD_MESSAGE_TO_QUEUE';
export const REMOVE_MESSAGE_FROM_QUEUE = 'REMOVE_MESSAGE_FROM_QUEUE';
export const START_LEARNING_SESSION = 'START_LEARNING_SESSION';
export const START_LEARNING_SESSION_SUCCESS = 'START_LEARNING_SESSION_SUCCESS';
export const START_LEARNING_SESSION_FAILURE = 'START_LEARNING_SESSION_FAILURE';
export const START_CALIBRATION_SESSION = 'START_CALIBRATION_SESSION';
export const START_CALIBRATION_SESSION_SUCCESS = 'START_CALIBRATION_SESSION_SUCCESS';
export const START_CALIBRATION_SESSION_FAILURE = 'START_CALIBRATION_SESSION_FAILURE';
export const UPDATE_SESSION_ID = 'UPDATE_SESSION_ID';
export const SET_WAITING_FOR_SERVER_RESPONSE = 'SET_WAITING_FOR_SERVER_RESPONSE';
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const LOAD_MESSAGE_HISTORY = 'LOAD_MESSAGE_HISTORY';
export const DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE';
export const SEND_RESUME_SESSION = 'SEND_RESUME_SESSION';
export const SEND_RESUME_SESSION_SUCCESS = 'SEND_RESUME_SESSION_SUCCESS';
export const SEND_RESUME_SESSION_FAILURE = 'SEND_RESUME_SESSION_FAILURE';
export const INITIATE_START_SESSION = 'INITIATE_START_SESSION';
export const RECONNECT_WEBSOCKET = 'RECONNECT_WEBSOCKET';


// Action Creators
export const updateSessionId = userLearningSessionID => ({
  type: UPDATE_SESSION_ID,
  payload: userLearningSessionID,
});

export const sendMessage = (messageObject) => ({
  type: SEND_MESSAGE,
  payload: { messageObject },
});

export const receiveMessage = (messageObject) => ({
  type: RECEIVE_MESSAGE,
  payload:  messageObject ,
});

export const displayMessage = (messageObject) => ({
  type: DISPLAY_MESSAGE,
  payload: messageObject,
});

export const addMessageToQueue = (messageObject) => ({
  type: ADD_MESSAGE_TO_QUEUE,
  payload: messageObject,
});

export const messageError = error => ({
  type: MESSAGE_ERROR,
  payload: error,
});

export const updateTypingStatus = isTyping => ({
  type: UPDATE_TYPING_STATUS,
  payload: isTyping,
});

export const setWaitingForServerResponse = (isWaiting) => ({
  type: SET_WAITING_FOR_SERVER_RESPONSE,
  payload: isWaiting,
});

export function removeMessageFromQueue() {
  console.log('Removing message from queue'); // Add this log
  return {
    type: REMOVE_MESSAGE_FROM_QUEUE,
  };
}

export const SET_SOCKET = 'SET_SOCKET';

export const setSocket = socket => {
  return {
    type: SET_SOCKET,
    payload: socket,
  };
};

export const startLearningSession = (sessionId) => ({
  type: START_LEARNING_SESSION,
  payload: sessionId,
});

export const startLearningSessionSuccess = (sessionId) => ({
  type: START_LEARNING_SESSION_SUCCESS,
  payload: sessionId,
});

export const startLearningSessionFailure = (error) => ({
  type: START_LEARNING_SESSION_FAILURE,
  payload: error,
});

export const startCalibrationSession = (courseId) => ({
  type: START_CALIBRATION_SESSION,
  payload: courseId,
});

export const startCalibrationSessionSuccess = (courseId) => ({
  type: START_CALIBRATION_SESSION_SUCCESS,
  payload: courseId,
});

export const startCalibrationSessionFailure = (error) => ({
  type: START_CALIBRATION_SESSION_FAILURE,
  payload: error,
});

export function loadMessageHistory(messages) {
  return {
    type: LOAD_MESSAGE_HISTORY,
    payload: messages
  };
}

export const displayErrorMessage = errorMessage => ({
  type: DISPLAY_ERROR_MESSAGE,
  payload: errorMessage,
});

export const sendResumeSession = (sessionId) => ({
  type: SEND_RESUME_SESSION,
  payload: sessionId,
});

export const sendResumeSessionSuccess = (sessionId) => ({
  type: SEND_RESUME_SESSION_SUCCESS,
  payload: sessionId,
});

export const sendResumeSessionFailure = (error) => ({
  type: SEND_RESUME_SESSION_FAILURE,
  payload: error,
});

export const initiateStartSession = sessionId => ({
  type: INITIATE_START_SESSION,
  payload: sessionId,
});

export const reconnectWebSocketAction = () => ({
  type: RECONNECT_WEBSOCKET,
});

