import React, { useState, useEffect } from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

const TypingAnimation = ({ message, speed = 100, onTypingDone = () => {} }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [displayedComponents, setDisplayedComponents] = useState([]);
  const [partIndex, setPartIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const parts = parseMessage(message);

  useEffect(() => {
    if (!parts || parts.length === 0) return;

    const currentPart = parts[partIndex];

    if (!currentPart) return; // Additional safety check

    if (currentPart.type === 'text') {
      if (charIndex < currentPart.content.length) {
        const timeoutId = setTimeout(() => {
          const nextChar = currentPart.content[charIndex];
          setDisplayedText(prev => prev + nextChar);
          setCharIndex(prev => prev + 1);
        }, speed);
        return () => clearTimeout(timeoutId);
      } else {
        // Move to the next part
        setDisplayedComponents(prev => [
          ...prev,
          <Box key={`text-${partIndex}`} p={2} mb={4}>
            <Text as="div" whiteSpace="pre-wrap">
              <ReactMarkdown>{displayedText}</ReactMarkdown>
            </Text>
          </Box>
        ]);
        setDisplayedText('');
        if (partIndex + 1 < parts.length) {
          setPartIndex(prev => prev + 1);
          setCharIndex(0);
        } else {
          onTypingDone();
        }
      }
    } else if (currentPart.type === 'image') {
      // Immediately display the image and move to the next part
      setDisplayedComponents(prev => [
        ...prev,
        <Image key={`image-${partIndex}`} src={`${process.env.REACT_APP_API_BASE_URL}/images/${currentPart.content}.png`} alt="" style={{ maxWidth: '100%', maxHeight: '400px', height: 'auto' }} mb={4} />
      ]);
      if (partIndex + 1 < parts.length) {
        setPartIndex(prev => prev + 1);
      } else {
        onTypingDone();
      }
    } else if (currentPart.type === 'break') {
      // Skip line break handling during typing
      if (partIndex + 1 < parts.length) {
        setPartIndex(prev => prev + 1);
      } else {
        onTypingDone();
      }
    }
  }, [charIndex, partIndex, parts, speed, onTypingDone, displayedText]);

  function parseMessage(message) {
    const imgRegex = /\{\{img:([^}]+)\}\}/g;
    let parts = [];
    let lastIndex = 0;

    // Handling images and text sections between images
    message.replace(imgRegex, (match, imageName, index) => {
      // Capture the text before the image
      if (lastIndex !== index) {
        const textSection = message.substring(lastIndex, index);
        // Splitting the text section by line breaks
        textSection.split('\n').forEach((part, idx) => {
          if (part) parts.push({ type: 'text', content: part });
          // Add a break part unless it's the last part
          if (idx < textSection.split('\n').length - 1) parts.push({ type: 'break' });
        });
      }
      // Add the image part
      parts.push({ type: 'image', content: imageName });
      lastIndex = index + match.length;
    });

    // Handle any remaining text after the last image or if no images are present
    const remainingText = message.substring(lastIndex);
    remainingText.split('\n').forEach((part, idx) => {
      if (part) parts.push({ type: 'text', content: part });
      // Add a break part unless it's the last part
      if (idx < remainingText.split('\n').length - 1) parts.push({ type: 'break' });
    });
    return parts;
  }

  return (
    <Box p={2}>
      {displayedComponents}
      {displayedText && (
        <Box p={2} mb={4} key={`typing`}>
          <Text as="div" whiteSpace="pre-wrap">
            <ReactMarkdown>{displayedText}</ReactMarkdown>
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default TypingAnimation;
