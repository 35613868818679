import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ConfirmEmail = () => {
  const [message, setMessage] = useState("Processing your email confirmation...");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get("token");

      if (!token) {
        setMessage("Invalid or missing token.");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/confirm-email`,
          { params: { token }, withCredentials: true } // Ensure cookies are handled
        );

        setMessage(response.data.message || "Email confirmed successfully!");

        // Redirect to the dashboard after a short delay
        setTimeout(() => {
          navigate("/app/dashboard");
        }, 500); // 2-second delay for user feedback
      } catch (error) {
        setMessage(
          error.response?.data?.detail || "An error occurred during confirmation."
        );
      }
    };

    confirmEmail();
  }, [location.search, navigate]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Email Confirmation</h1>
      <p>{message}</p>
    </div>
  );
};

export default ConfirmEmail;
