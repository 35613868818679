// LearningSchedule.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Text,
  Flex,
  Badge,
  LinkBox,
  Button,
  VStack,
  Tooltip,
  Progress,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { LockIcon } from '@chakra-ui/icons';
import { createCheckoutSessionApi } from '../../utils/api';
import { startCalibrationSession } from '../../redux/actions/chatActions';
import { closeAllComponents } from '../../redux/actions/headerActions';
import {
  fetchLearningScheduleRequest,
  checkLearningSessionLockedStatusRequest,
} from '../../redux/actions/learningScheduleActions';

const LearningSchedule = () => {
  const bgColor = 'lightGrayishWhite'; // Updated to match the light design
  const cardColor = 'backgroundWhite'; // Use white for cards
  const textColor = 'darkGray.500'; // For text contrast

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    schedule,
    loading,
    error,
    calibrationRequired,
    courseId,
  } = useSelector((state) => state.learningSchedule);

  useEffect(() => {
    dispatch(fetchLearningScheduleRequest());
  }, [dispatch]);

  const isObjectiveMastered = (objectives) => {
    return objectives && objectives.some((obj) => obj.mastery_level >= 3);
  };

  const handleSessionClick = (sessionId) => {
    dispatch(checkLearningSessionLockedStatusRequest(sessionId));
    dispatch(closeAllComponents());
  };

  const handleCalibrateClick = (courseId) => {
    dispatch(startCalibrationSession(courseId));
    dispatch(closeAllComponents());
  };

  const handleUpgradeNow = async (courseId) => {
    try {
      const { url } = await createCheckoutSessionApi(courseId);
      if (url) {
        window.location.href = url;
        dispatch(closeAllComponents());
      } else {
        console.error('Failed to get checkout URL');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error: {error}</Text>;
  }

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const dayWidth = `calc(50vw / ${days.length})`;

  if (calibrationRequired) {
    return (
      <Box
        overflowX="auto"
        width="50vw"
        mx="auto"
        p={0}
        bg={bgColor}
        borderRadius="lg"
        boxShadow="lg"
      >
        <VStack spacing={4} align="center" justify="center" height="60vh">
          <Text fontSize="2xl" fontWeight="bold" color={textColor}>
            Calibration Required
          </Text>
          <Text textAlign="center" color={textColor}>
            To generate your personal learning plan, we need to calibrate the course for you.
          </Text>
          <Button
            size="sm"
            colorScheme="primary"
            variant="solid"
            _hover={{ bg: 'lightPeach.500' }}
            onClick={() => handleCalibrateClick(courseId)}
          >
            Calibrate
          </Button>
        </VStack>
      </Box>
    );
  }

  return (
    <Box
      overflowX="auto"
      width="50vw"
      mx="auto"
      p={0}
      bg={bgColor}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Box bg={bgColor} p={4} textAlign="center" borderRadius="lg">
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text fontSize="lg" fontWeight="bold" color={textColor}>
              Learned:
            </Text>
            <Progress
              value={schedule.objectives_learned}
              max={schedule.objectives_scheduled}
              colorScheme="primary"
            />
            <Text fontSize="sm" color={textColor}>
              {schedule.objectives_learned} / {schedule.objectives_scheduled}
            </Text>
          </Box>
          <Box>
            <Text fontSize="lg" fontWeight="bold" color={textColor}>
              Reinforced:
            </Text>
            <Progress
              value={schedule.objectives_reinforced}
              max={schedule.reinforced_goal}
              colorScheme="primary"
            />
            <Text fontSize="sm" color={textColor}>
              {schedule.objectives_reinforced} | Goal: {schedule.reinforced_goal}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Flex direction="row" spacing={0} width="100%" minWidth="max-content">
        {days.map((day, index) => (
          <VStack
            key={index}
            spacing={2}
            align="stretch"
            width={dayWidth}
            minWidth={dayWidth}
          >
            <Text
              fontSize="sm"
              fontWeight="bold"
              p={2}
              textAlign="center"
              bg={cardColor}
              position="sticky"
              top="0"
              zIndex="sticky"
              color={textColor}
            >
              {day}
            </Text>
            <VStack spacing={2} overflowY="auto" maxH="60vh">
              {schedule[day.toLowerCase()] &&
                schedule[day.toLowerCase()].map((item) => (
                  <Tooltip
                    key={item.id}
                    label={item.session_title}
                    placement="top"
                    hasArrow
                  >
                    {item.is_locked ? (
                      <Button
                        size="sm"
                        colorScheme="primary"
                        variant="solid"
                        _hover={{ bg: 'lightPeach.500' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleUpgradeNow(item.course_id);
                        }}
                        leftIcon={<LockIcon />}
                      >
                        Upgrade Now
                      </Button>
                    ) : (
                      <LinkBox
                        size="sm"
                        colorScheme="primary"
                        variant="outline"
                        _hover={{ bg: 'lightPeach.500' }}
                        cursor="pointer"
                        onClick={() => handleSessionClick(item.id)}
                        width="95%"
                        borderWidth="1px"
                        borderColor="lightGray.500"
                        borderRadius="md"
                        p={2}
                        bg={cardColor}
                      >
                        <Badge
                          colorScheme={
                            isObjectiveMastered(item.objectives) ? 'green' : 'blue'
                          }
                          mb={1}
                        >
                          {item.type}
                        </Badge>
                        <Text noOfLines={1} fontSize="xs" color={textColor}>
                          {item.session_title}
                        </Text>
                      </LinkBox>
                    )}
                  </Tooltip>
                ))}
            </VStack>
          </VStack>
        ))}
      </Flex>
    </Box>
  );
};

export default LearningSchedule;
