import { LOGIN_SUCCESS, LOGOUT_SUCCESS, LOGOUT_FAILURE, VERIFY_TOKEN_FAILURE, REGISTER_SUCCESS, REGISTER_FAILURE, RESET_REGISTER_SUCCESS } from '../actions/authActions';

const initialState = {
  isAuthenticated: false,
  user: null,
  isRegistered: false, // Flag to indicate registration status
};

const authReducer = (state = initialState, action) => {
  console.log("Action:", action.type, "New State:", { isAuthenticated: state.isAuthenticated, user: state.user });
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case LOGOUT_FAILURE:
      return state;

    case VERIFY_TOKEN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
      };

    case REGISTER_SUCCESS:
      console.log("Registration successful, updating state", action.payload);
      return {
        ...state,
        isRegistered: true, // Set registration success flag
      };

    case REGISTER_FAILURE:
        console.error("Registration failed, updating state with error", action.payload);
        return { ...state, isAuthenticated: false, error: action.payload };

    case RESET_REGISTER_SUCCESS:
      return {
        ...state,
        isRegistered: false, // Reset registration success flag
      };

    default:
      return state;
  }
};

export default authReducer;
