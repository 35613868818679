import axios from 'axios';
import { refreshTokenRequest, logoutSuccess } from '../redux/actions/authActions';
import store from '../redux/store'; // Assuming you need this for dispatching actions

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

// Axios response interceptor
axios.interceptors.response.use(
  response => response, // Pass through for successful responses
  async (error) => {
    const originalRequest = error.config;

    // Check specific conditions for attempting a token refresh
    if (error.response?.status === 401 && !originalRequest._retry && originalRequest.url !== `${API_BASE_URL}/refresh-token`) {
      console.log('Access token expired, attempting to refresh...');

      originalRequest._retry = true; // Mark this request as retried to prevent infinite loops

      try {
        // Dispatch refreshTokenRequest to trigger the refresh logic
        await store.dispatch(refreshTokenRequest());
        // Note: The actual refresh logic should handle setting the new token via HttpOnly cookie
        await new Promise(resolve => setTimeout(resolve, 50));
        // Retry the original request without manually altering headers
        // The server should automatically include the new token in HttpOnly cookies for subsequent requests
        return axios(originalRequest);
      } catch (refreshError) {
        console.log('Refresh token failed, logging out...');
        // Dispatch an action to handle logout
        store.dispatch(logoutSuccess());
        return Promise.reject(refreshError);
      }
    }

    // For errors not handled above, just reject the promise
    return Promise.reject(error);
  }
);

export const registerApi = async (newUser) => {
    try {
        console.log("Sending registration request to API:", newUser);
        console.log("API Base URL:", API_BASE_URL);
        const response = await axios.post(`${API_BASE_URL}/register`, newUser, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("Registration response from API:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error in registerApi:", error.response || error.message);
        throw error;
    }
};

export async function loginUser(credentials) {
    try {
        const response = await axios.post(`${API_BASE_URL}/token`, credentials, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        if (response.status === 200) {
            // The token is now set as a cookie by the backend, no need to handle it here
            return { success: true };
        } else {
            console.error('loginUser: Login failed:', response.data.message);
            return { success: false, message: response.data.message };
        }
    } catch (error) {
        console.error('loginUser: Error occurred:', error);
        return { success: false, message: error.message };
    }
}


export const verifyTokenApi = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/verify-token`);
        return response.data; // This should include the user data if the token is valid
    } catch (error) {
        throw error; // Rethrowing the error for the saga to catch
    }
};


export const refreshTokenApi = async () => {
    try {
        const response = await axios.post(`${API_BASE_URL}/refresh-token`, {}, {
            withCredentials: true, // Important for including cookies in the request
        });
        return response.data; 
        console.log(response.data)
    } catch (error) {
        throw error; // Rethrow the error for the saga to catch
    }
};

export const logoutUser = async () => {
    try {
        console.log("here")
        const response = await axios.post(`${API_BASE_URL}/logout`, {}, {
            withCredentials: true

        });
        console.log(response)
        if (response.status === 200) {
            return { success: true };
        } else {
            return { success: false, message: 'Logout failed' };
        }
    } catch (error) {
        return { success: false, message: error.message };
    }
};

function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// ... other API functions ...

export const fetchLearnerProfileDataApi = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/learner-profile/mastery-levels`);
        return response.data;
        console.log("mastery-levels", response.data)
    } catch (error) {
        console.log("EEEEEROR")
        throw error; // Rethrowing the error for the saga to catch
    }
};

export const fetchSearchSuggestionsApi = async (query) => {
    const response = await axios.get(`${API_BASE_URL}/search_suggestions`, {
        params: { query }
    });
    return response.data;
};

export const addUserCourseApi = async (courseData) => {
    const response = await axios.post(`${API_BASE_URL}/add_user_course`, courseData);
    return response.data;
};

export const fetchLearningScheduleApi = async () => {
  const response = await axios.get(`${API_BASE_URL}/learning-plan/items`);
  return response.data;
};

export const updateSettingsApi = async (settings) => {
  const response = await axios.post(`${API_BASE_URL}/update-settings`, settings);
  return response.data;
};

export const createCheckoutSessionApi = async (courseId) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/create-checkout-session/${courseId}`);
        return response.data; // Assuming the API returns the checkout URL or session object
    } catch (error) {
        throw error; // Rethrow the error for handling in the component
    }
};

export const checkLockedStatusApi = async (courseId, objectiveId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/check-objective-locked/${courseId}/${objectiveId}`);
        return response.data; // Assuming the API returns the locked status and session ID if available
    } catch (error) {
        throw error; // Rethrow the error for the saga to catch
    }
};

export const checkLearningSessionLockedStatusApi = async (sessionId) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/check-session-locked/${sessionId}`);
        return response.data; // Assuming the API returns the special ID if the session is unlocked
    } catch (error) {
        throw error; // Rethrow the error for the saga to catch
    }
};

export const getCalibrationSessionId = async (courseId) => {
    try {
        console.log('getCalibrationSessionId')
        const response = await axios.post(`${API_BASE_URL}/get-calibration-session/${courseId}`);
        console.log('getCalibrationSessionId2', response.data.specialSessionId)
        return response.data.specialSessionId;
    } catch (error) {
        throw error;
    }
};

const mockSettingsData = {
  viewMode: 'week',  // Can be 'week' or 'kanban'
  cardMode: 'fixed', // Can be 'fixed' or 'moveable'
  weeklyHours: 20    // Default weekly hours
};

export const fetchUserSettingsApi = async () => {
  // Simulate a network response delay
  await new Promise(resolve => setTimeout(resolve, 1000));
  
  return mockSettingsData;
};

