import { SET_ACTIVITY_TIMER, SET_SESSION_TIMER, DECREMENT_TIMERS } from '../actions/timerActions';

const initialState = {
  activityTimer: { endTime: null, show: false },
  sessionTimer: { endTime: null, show: false },
};

export const timerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVITY_TIMER:
      return { ...state, activityTimer: action.payload };
    case SET_SESSION_TIMER:
      return { ...state, sessionTimer: action.payload };
    default:
      return state;
  }
};


export default timerReducer;