import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FETCH_LEARNING_SCHEDULE_REQUEST,
  fetchLearningScheduleSuccess,
  fetchLearningScheduleFailure,
  UPDATE_SETTINGS_REQUEST,
  updateSettingsSuccess,
  updateSettingsFailure,
  FETCH_USER_SETTINGS_REQUEST,
  CHECK_LEARNING_SESSION_LOCKED_STATUS_REQUEST,
  fetchUserSettingsSuccess,
  fetchUserSettingsFailure,
  checkLearningSessionLockedStatusFailure,
  checkLearningSessionLockedStatusSuccess
} from '../actions/learningScheduleActions';
import { initiateStartSession } from '../actions/chatActions'
import { fetchLearningScheduleApi, updateSettingsApi, fetchUserSettingsApi, checkLearningSessionLockedStatusApi } from '../../utils/api';

function* fetchLearningScheduleSaga() {
  try {
    const response = yield call(fetchLearningScheduleApi);
    console.log("API Response:", response);  // Log the API response
    yield put(fetchLearningScheduleSuccess(response));
  } catch (error) {
    yield put(fetchLearningScheduleFailure(error.message));
  }
}

function* updateSettingsSaga(action) {
  try {
    const response = yield call(updateSettingsApi, action.payload);
    yield put(updateSettingsSuccess(response.data));
  } catch (error) {
    yield put(updateSettingsFailure(error.message));
  }
}

function* fetchUserSettingsSaga() {
  try {
    const response = yield call(fetchUserSettingsApi); // API call to fetch settings
    yield put(fetchUserSettingsSuccess(response.data));
  } catch (error) {
    yield put(fetchUserSettingsFailure(error.message));
  }
}

function* checkLearningSessionLockedStatusSaga(action) {
  try {
    const { sessionId } = action.payload;
    const data = yield call(checkLearningSessionLockedStatusApi, sessionId);
    if (data && !data.error) {
      yield put(checkLearningSessionLockedStatusSuccess(data));
      if (data) {
        yield put(initiateStartSession(data));
      }
    } else {
      yield put(checkLearningSessionLockedStatusFailure('Session is locked or not found'));
    }
  } catch (error) {
    yield put(checkLearningSessionLockedStatusFailure(error.toString()));
  }
}


export default function* learningScheduleSaga() {
  yield takeEvery(FETCH_LEARNING_SCHEDULE_REQUEST, fetchLearningScheduleSaga);
  yield takeEvery(UPDATE_SETTINGS_REQUEST, updateSettingsSaga);
  yield takeLatest(FETCH_USER_SETTINGS_REQUEST, fetchUserSettingsSaga);
  yield takeEvery(CHECK_LEARNING_SESSION_LOCKED_STATUS_REQUEST, checkLearningSessionLockedStatusSaga);
}


