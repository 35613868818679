import {
  FETCH_LEARNING_SCHEDULE_REQUEST,
  FETCH_LEARNING_SCHEDULE_SUCCESS,
  FETCH_LEARNING_SCHEDULE_FAILURE,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  FETCH_USER_SETTINGS_REQUEST,
  FETCH_USER_SETTINGS_SUCCESS,
  FETCH_USER_SETTINGS_FAILURE,
  UPDATE_USER_SETTINGS,
} from '../actions/learningScheduleActions';

const initialState = {
  loading: false,
  schedule: {},
  error: null,
  settings: {},
  viewMode: 'week', // Default mode
  cardMode: 'fixed', // Default mode
  weeklyHours: 20, // Default hours
  calibrationRequired: false, // Default calibration state
  courseId: null, // Default course ID, null if no calibration is needed
};

const learningScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEARNING_SCHEDULE_REQUEST:
      return { ...state, loading: true };
    case FETCH_LEARNING_SCHEDULE_SUCCESS:
      // Check if the payload contains a calibration flag
      if ('calibration_required' in action.payload) {
        // Update state with calibration requirement and the associated courseId
        return {
          ...state,
          loading: false,
          calibrationRequired: action.payload.calibration_required,
          courseId: action.payload.course_id || null, // Ensure courseId is updated accordingly, or set to null if not provided
        };
      } else {
        // Normal schedule update without calibration requirement
        return { ...state, loading: false, schedule: action.payload, courseId: null }; // Reset courseId to null when not required
      }
    case FETCH_LEARNING_SCHEDULE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_SETTINGS_REQUEST:
      return { ...state, loading: true };
    case UPDATE_SETTINGS_SUCCESS:
      return { ...state, loading: false, settings: action.payload };
    case UPDATE_SETTINGS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_USER_SETTINGS_REQUEST:
      return { ...state };
    case FETCH_USER_SETTINGS_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_USER_SETTINGS_FAILURE:
      return { ...state, error: action.payload };
    case UPDATE_USER_SETTINGS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default learningScheduleReducer;

