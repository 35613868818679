export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const VERIFY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST';
export const VERIFY_TOKEN_FAILURE = 'VERIFY_TOKEN_FAILURE';
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const RESET_REGISTER_SUCCESS = 'RESET_REGISTER_SUCCESS';

export const registerRequest = (newUser) => ({
  type: REGISTER_REQUEST,
  payload: newUser,
});

export const registerSuccess = (userData) => ({
  type: REGISTER_SUCCESS,
  payload: userData,
});

export const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

// Action creator for initiating a login request
export const loginRequest = (credentials) => ({
  type: LOGIN_REQUEST,
  payload: credentials,
});

// Action creator for handling a successful login
export const loginSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
});

// Action creator for handling a login failure
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

// Action creator for initiating a logout request
export const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
});

// Action creator for a successful logout
export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

// Action creator for a failed logout attempt
export const logoutFailure = (error) => ({
  type: LOGOUT_FAILURE,
  payload: error,
});

// Action creator for verifying the authentication token
export const verifyTokenRequest = () => ({
  type: VERIFY_TOKEN_REQUEST,
});

export const verifyTokenFailure = () => ({
  type: VERIFY_TOKEN_FAILURE,
});  

export const resetRegisterSuccess = () => ({
  type: RESET_REGISTER_SUCCESS,
});


// Action creator for verifying the authentication token
export const refreshTokenRequest = () => ({
  type: REFRESH_TOKEN_REQUEST,
});


