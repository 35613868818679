import { combineReducers } from 'redux';
import authReducer from './authReducer';
import chatReducer from './chatReducer';
import timerReducer from './timerReducer';
import learnerProfileReducer from './learnerProfileReducer';
import learningScheduleReducer from './learningScheduleReducer';
import notepadReducer from './notepadReducer';
import flashcardReducer from './flashcardReducer'
import headerReducer from './headerReducer'


const rootReducer = combineReducers({
  auth: authReducer,
  chat: chatReducer,
  timer: timerReducer,
  learnerProfile: learnerProfileReducer,
  learningSchedule: learningScheduleReducer,
  notepad: notepadReducer,
  flashcard: flashcardReducer,
  header: headerReducer
  // ... other reducers
});

export default rootReducer;
