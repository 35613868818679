export const FETCH_LEARNING_SCHEDULE_REQUEST = 'FETCH_LEARNING_SCHEDULE_REQUEST';
export const FETCH_LEARNING_SCHEDULE_SUCCESS = 'FETCH_LEARNING_SCHEDULE_SUCCESS';
export const FETCH_LEARNING_SCHEDULE_FAILURE = 'FETCH_LEARNING_SCHEDULE_FAILURE';
export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';
export const FETCH_USER_SETTINGS_REQUEST = 'FETCH_USER_SETTINGS_REQUEST';
export const FETCH_USER_SETTINGS_SUCCESS = 'FETCH_USER_SETTINGS_SUCCESS';
export const FETCH_USER_SETTINGS_FAILURE = 'FETCH_USER_SETTINGS_FAILURE';
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const CHECK_LEARNING_SESSION_LOCKED_STATUS_REQUEST = 'CHECK_LEARNING_SESSION_LOCKED_STATUS_REQUEST';
export const CHECK_LEARNING_SESSION_LOCKED_STATUS_SUCCESS = 'CHECK_LEARNING_SESSION_LOCKED_STATUS_SUCCESS';
export const CHECK_LEARNING_SESSION_LOCKED_STATUS_FAILURE = 'CHECK_LEARNING_SESSION_LOCKED_STATUS_FAILURE';

export const fetchLearningScheduleRequest = () => ({
  type: FETCH_LEARNING_SCHEDULE_REQUEST,
});

export const fetchLearningScheduleSuccess = (data) => ({
  type: FETCH_LEARNING_SCHEDULE_SUCCESS,
  payload: data,
});

export const fetchLearningScheduleFailure = (error) => ({
  type: FETCH_LEARNING_SCHEDULE_FAILURE,
  payload: error,
});

export const updateSettingsRequest = (settings) => ({
  type: UPDATE_SETTINGS_REQUEST,
  payload: settings,
});

export const updateSettingsSuccess = (message) => ({
  type: UPDATE_SETTINGS_SUCCESS,
  payload: message,
});

export const updateSettingsFailure = (error) => ({
  type: UPDATE_SETTINGS_FAILURE,
  payload: error,
});

export const fetchUserSettingsRequest = () => ({
  type: FETCH_USER_SETTINGS_REQUEST,
});

export const fetchUserSettingsSuccess = (settings) => ({
  type: FETCH_USER_SETTINGS_SUCCESS,
  payload: settings,
});

export const fetchUserSettingsFailure = (error) => ({
  type: FETCH_USER_SETTINGS_FAILURE,
  payload: error,
});

export const updateUserSettings = (settings) => ({
  type: UPDATE_USER_SETTINGS,
  payload: settings,
});

export const checkLearningSessionLockedStatusRequest = (sessionId) => ({
  type: CHECK_LEARNING_SESSION_LOCKED_STATUS_REQUEST,
  payload: { sessionId },
});

export const checkLearningSessionLockedStatusSuccess = (data) => ({
  type: CHECK_LEARNING_SESSION_LOCKED_STATUS_SUCCESS,
  payload: data,
});

export const checkLearningSessionLockedStatusFailure = (error) => ({
  type: CHECK_LEARNING_SESSION_LOCKED_STATUS_FAILURE,
  payload: { error },
});
