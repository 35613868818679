import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const calculateRemainingTime = (endTime) => {
  const now = new Date();
  const end = new Date(endTime);
  return Math.max(0, Math.floor((end - now) / 1000));
};

const SessionTimer = () => {
  const { endTime, show } = useSelector(state => state.timer.sessionTimer);
  const [remainingTime, setRemainingTime] = useState(() => calculateRemainingTime(endTime));

  useEffect(() => {
    if (endTime) {
      const updateRemainingTime = () => {
        setRemainingTime(calculateRemainingTime(endTime));
      };

      updateRemainingTime(); // Immediately update remaining time
      const interval = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(interval);
    }
  }, [endTime]);

  if (!show || remainingTime <= 0) return null;

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;
  const displayTime = minutes >= 1 ? `${minutes} min` : `${seconds} sec`;

  return <div className="session-timer">{displayTime}</div>;
};

export default SessionTimer;
