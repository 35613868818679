import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  CREATE_FLASHCARD_REQUEST,
  createFlashcardSuccess,
  createFlashcardFailure,
  FETCH_FLASHCARDS_REQUEST,
  fetchFlashcardsSuccess,
  fetchFlashcardsFailure,
  RATE_FLASHCARD_REQUEST,
  rateFlashcardSuccess,
  rateFlashcardFailure,
} from '../actions/flashcardActions';

function* createFlashcardSaga(action) {
  try {
    const { data } = yield call(axios.post, '/api/flashcards', action.payload);
    yield put(createFlashcardSuccess(data));
  } catch (error) {
    yield put(createFlashcardFailure(error.message));
  }
}

function* fetchFlashcardsSaga() {
  try {
    const { data } = yield call(axios.get, '/api/flashcards');
    yield put(fetchFlashcardsSuccess(data));
  } catch (error) {
    yield put(fetchFlashcardsFailure(error.message));
  }
}

function* rateFlashcardSaga(action) {
  try {
    const { id, rating } = action.payload;
    const { data } = yield call(axios.patch, `/api/flashcards/${id}/rate`, { rating });
    yield put(rateFlashcardSuccess(data));
  } catch (error) {
    yield put(rateFlashcardFailure(error.message));
  }
}

export default function* flashcardSaga() {
  yield takeLatest(CREATE_FLASHCARD_REQUEST, createFlashcardSaga);
  yield takeLatest(FETCH_FLASHCARDS_REQUEST, fetchFlashcardsSaga);
  yield takeLatest(RATE_FLASHCARD_REQUEST, rateFlashcardSaga);
}
