import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Image,
  Text,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../assets/images/Logo.png'; // Ensure this path is correct
import { resetRegisterSuccess } from '../redux/actions/authActions';

const Registration = () => {
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  
  const [newUser, setNewUser] = useState({
    full_name: '',
    email: '',
    password: '',
  });

  const isRegistered = useSelector((state) => state.auth.isRegistered);
  const authError = useSelector(state => state.auth.error); // Assuming you have an error field
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  // Theme colors
  const primaryColor = 'primary.500';
  const mutedRedColor = 'mutedRed.500';
  const textColor = 'darkGray.500'; // Matches Chat.js
  const placeholderColor = 'mediumGray.500'; // Matches Chat.js placeholders
  const lightGrayishWhite = 'lightGrayishWhite';
  const backgroundWhite = 'backgroundWhite';
  const lightPeach = 'lightPeach.500';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting registration:', newUser);
    dispatch({ type: 'REGISTER_REQUEST', payload: newUser });
  };

  useEffect(() => {
    if (isRegistered) {
      setShowConfirmationMessage(true);
      dispatch(resetRegisterSuccess());
    }
  }, [isRegistered, dispatch]);

  return (
    <Flex align="center" justify="center" minH="100vh" bg={lightGrayishWhite}>
      <Box
        w="full"
        maxW="md"
        p={8}
        borderRadius="lg"
        bg={backgroundWhite}
        boxShadow="lg"
      >
        <Flex justifyContent="center" mb={6}>
          <Image src={logo} alt="Company Logo" objectFit="contain" h="50px" />
        </Flex>
        {showConfirmationMessage ? (
          <Text color={primaryColor} mt={4} textAlign="center">
            Registration successful! Please check your email to confirm your account.
          </Text>
        ) : (
          <form onSubmit={handleSubmit}>
            <FormControl id="full_name" isRequired mb={4}>
              <FormLabel color={textColor}>Full Name</FormLabel>
              <Input
                type="text"
                name="full_name"
                value={newUser.full_name}
                onChange={handleChange}
                bg="lightGray.500" // Keep the original background
                color={textColor} // Match Chat.js text color
                _placeholder={{ color: placeholderColor }} // Match Chat.js placeholder color
              />
            </FormControl>
            <FormControl id="email" isRequired mb={4}>
              <FormLabel color={textColor}>Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={newUser.email}
                onChange={handleChange}
                bg="lightGray.500" // Keep the original background
                color={textColor} // Match Chat.js text color
                _placeholder={{ color: placeholderColor }} // Match Chat.js placeholder color
              />
            </FormControl>
            <FormControl id="password" isRequired mb={6}>
              <FormLabel color={textColor}>Password</FormLabel>
              <Input
                type="password"
                name="password"
                value={newUser.password}
                onChange={handleChange}
                bg="lightGray.500" // Keep the original background
                color={textColor} // Match Chat.js text color
                _placeholder={{ color: placeholderColor }} // Match Chat.js placeholder color
              />
            </FormControl>
            <Button
              type="submit"
              color={backgroundWhite}
              bg={primaryColor}
              _hover={{ bg: lightPeach }}
              size="lg"
              fontSize="md"
              w="full"
            >
              Register
            </Button>
          </form>
        )}
        {!showConfirmationMessage && (
          <Text color={textColor} mt={6} textAlign="center">
            Already have an account?{' '}
            <ChakraLink as={Link} to="/login" color={primaryColor}>
              Log in here
            </ChakraLink>
          </Text>
        )}
        {authError && !showConfirmationMessage && (
          <Text color={mutedRedColor} mt={4} textAlign="center">
            {authError}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default Registration;
