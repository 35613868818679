import { race, takeEvery, put, call, fork, take, select, all, delay } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
  SEND_MESSAGE,
  receiveMessage,
  messageError,
  addMessageToQueue,
  removeMessageFromQueue,
  updateTypingStatus,
  setSocket,
  SET_SOCKET,
  START_LEARNING_SESSION,
  startLearningSessionSuccess,
  startLearningSessionFailure,
  START_CALIBRATION_SESSION,
  startCalibrationSessionSuccess,
  startCalibrationSessionFailure,
  UPDATE_MESSAGE_PART,
  updateMessagePart,
  updateSessionId,
  setWaitingForServerResponse,
  RECEIVE_MESSAGE,
  displayMessage,
  startLearningSession,
  loadMessageHistory,
  displayErrorMessage,
  SEND_RESUME_SESSION,
  sendResumeSession,
  INITIATE_START_SESSION,

} from '../actions/chatActions';
import { SET_ACTIVITY_TIMER, SET_SESSION_TIMER, setActivityTimer, setSessionTimer } from '../actions/timerActions';
import { convertSessionId, getCalibrationSessionId } from '../../utils/api';
import store from '../store';

export { reconnectWebSocket };


const getSessionId = state => state.chat.userLearningSessionID;

function* initializeWebSocket() {
  const socketChannel = yield call(createWebSocketConnection);

  let socket;
  try {
    while (true) {
      const action = yield take(socketChannel);
      if (action.type === SET_SOCKET) {
        socket = action.payload;
        yield put(setSocket(socket)); // Store the socket in the Redux state

        // Set up the socket.onclose handler without `yield`
        socket.onclose = () => {
          console.log('WebSocket closed, attempting to reconnect...');
          // Use `store.dispatch` to dispatch an action to trigger reconnection
          store.dispatch({ type: 'RECONNECT_WEBSOCKET' });
        };
      } else {
        yield put(action); // Handle other actions from the eventChannel
      }
    }
  } catch (error) {
    // Handle any initialization errors here
    yield put(displayErrorMessage(`WebSocket initialization failed: ${error.message}`));
    store.dispatch({ type: 'RECONNECT_WEBSOCKET' }); // Trigger reconnection on error
  }
}


function* watchReconnectWebSocket() {
  yield takeEvery('RECONNECT_WEBSOCKET', reconnectWebSocket);
}


function createWebSocketConnection() {
  return eventChannel((emit) => {
    const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}`);


    socket.onopen = () => {
      console.log("[WebSocket] Connection opened.");
      emit(setSocket(socket));
    };

    socket.onmessage = (event) => {
      const messageData = JSON.parse(event.data);
      console.log(`[WebSocket] Full Message Data Received:`, messageData);

      // Handle messages based on type
      switch (messageData.type) {
        case "resume_session":
          emit(updateSessionId(messageData.current_learning_session_special_id));
          break;
        case "session_history":
          console.log("[WebSocket] Handling session history", messageData.history);
          emit(loadMessageHistory(messageData.history.map(msg => ({
            ...msg,
            userLearningSessionID: messageData.user_learning_session_id,
          }))));
          break;
        case "message":
          console.log("[WebSocket] Handling individual message type");
          if (messageData.message) {
            const messageObject = {
              message: messageData.message,
              userLearningSessionID: messageData.user_learning_session_id,
              author: 'Tutor',
            };
            console.log("[WebSocket] Emitting received message", messageObject);
            emit(receiveMessage(messageObject));
          }
          break;
        default:
          console.log(`[WebSocket] Unhandled message type: ${messageData.type}`);
          break;
      }

      // Handle timer messages
      if (messageData.activityTimer || messageData.sessionTimer) {
        console.log("[WebSocket] Handling timer update", messageData);

        // Handle the timer updates
        if (messageData.activityTimer) {
          emit(setActivityTimer(messageData.activityTimer.endTime, messageData.activityTimer.show));
        }
        if (messageData.sessionTimer) {
          emit(setSessionTimer(messageData.sessionTimer.endTime, messageData.sessionTimer.show));
        }

        // Also handle the message if present
        if (messageData.message) {
          console.log("[WebSocket] Handling message within timer update");
          const messageObject = {
            message: messageData.message,
            userLearningSessionID: messageData.user_learning_session_id || null,
            author: 'Tutor',
          };
          console.log("[WebSocket] Emitting received message", messageObject);
          emit(receiveMessage(messageObject));
        }
      }
    };

    socket.onerror = (error) => {
      console.error("[WebSocket] Error occurred:", error.message);
      emit(messageError(`WebSocket error: ${error.message}`));
    };

    socket.onclose = (event) => {
      console.warn(`[WebSocket] Connection closed (code: ${event.code}, reason: ${event.reason})`);
      emit({ type: 'WEBSOCKET_CLOSED', payload: { code: event.code, reason: event.reason } });
    };

    return () => {
      console.log("[WebSocket] Closing connection.");
      socket.close();
    };
  });
}



function sendMessageWebSocket(messageObject, socket) {

  if (socket.readyState === WebSocket.OPEN) {
    socket.send(JSON.stringify(messageObject));
  } else if (socket.readyState === WebSocket.CONNECTING) {
    setTimeout(() => sendMessageWebSocket(messageObject, socket), 500);
  } else {
    store.dispatch({ type: 'RECONNECT_WEBSOCKET' });
  }
}




function* handleSendMessage(action) {

  const { waitingForServerResponse } = yield select(state => state.chat);
  if (action.payload.messageObject.author === 'self' && waitingForServerResponse) {
    // Ignore or specially handle user messages if we are waiting for a server response
    return;
  }
  if (action.payload.messageObject.author === 'self') {
    const socket = yield select(state => state.chat.socket);
    yield call(sendMessageWebSocket, action.payload.messageObject, socket);
    yield put(addMessageToQueue(action.payload.messageObject));
    yield put(setWaitingForServerResponse(true));
  }
}

function* watchReceiveMessage(action) {
    const { processed, timeout } = yield race({
        processed: call(processMessage, action.payload),
        timeout: delay(15000)  // 15 second timeout
    });

    if (timeout) {
        yield put(displayErrorMessage('Server is not responding, please refresh the page.'));
    }
}

function* processMessage(message) {
    yield delay(1000);
    yield put(addMessageToQueue(message));
    yield put(setWaitingForServerResponse(false));
    if (message.author === 'Tutor') {
        yield put(updateTypingStatus(true));
    }
}

function* processMessageQueue() {
  while (true) {
    yield delay(10);
    const { messageQueue, isTyping } = yield select(state => state.chat);
    if (messageQueue.length > 0) {
      const nextMessageObject = messageQueue[0];
      if (messageQueue.length === 1) {
        if (nextMessageObject.author === 'Tutor' && !isTyping) {
          yield put(updateTypingStatus(true)); 
        } else if (nextMessageObject.author !== 'Tutor'){
          yield put(displayMessage(nextMessageObject));
          yield put(removeMessageFromQueue());
        }
      } else {
        yield put(displayMessage(nextMessageObject));
        yield put(removeMessageFromQueue());
      }
    } else {
      yield delay(50); // Wait before checking the queue again if empty
    }
  }
}

// If from websocket break -> reconnect via this function
function* sendResumeSessionMessage(action) {
  const sessionId = action.payload;
  const socket = yield select(state => state.chat.socket);
  const message = {
    type: 'message',
    userLearningSessionID: sessionId
  };

  socket.send(JSON.stringify(message));
}

function* startCalibrationSessionSaga(action) {
  const courseId = action.payload;
  const specialSessionId = yield call(getCalibrationSessionId, courseId);
  console.log('specialSessionId',specialSessionId)
  yield put(startLearningSession(specialSessionId));
}

function* sendStartSessionMessage(action) {
  const sessionId = action.payload;
  console.log('Starting new session with ID:', sessionId);
  const socket = yield select(state => state.chat.socket);
  const message = {
    type: 'message',
    userLearningSessionID: sessionId
  };

  socket.send(JSON.stringify(message));
  console.log('Started session with ID:', sessionId);
}


function* pauseCurrentSession() {
  const currentSessionId = yield select(getSessionId);
  
  if (currentSessionId) {
    console.log('Pausing current session with ID:', currentSessionId);
    
    const socket = yield select(state => state.chat.socket);
    const message = {
      type: 'pause_session',
      sessionId: currentSessionId,
    };

    // Send the pause message to the backend via WebSocket
    yield call(sendMessageWebSocket, message, socket);
    
    console.log('Paused session with ID:', currentSessionId);
    
    // Clear the activity and session timers in the Redux store
    yield put(setActivityTimer(null, false));
    yield put(setSessionTimer(null, false));
    
    console.log('Cleared timers from Redux store.');
  }
}


function* reconnectWebSocket() {
  const existingSocket = yield select((state) => state.chat.socket);
  if (existingSocket) {
    existingSocket.close(); // Close the existing connection
  }

  yield delay(500); // Delay to ensure the socket closes properly

  const socketChannel = yield call(createWebSocketConnection);

  try {
    while (true) {
      const action = yield take(socketChannel);
      if (action.type === SET_SOCKET) {
        yield put(setSocket(action.payload)); // Save the new WebSocket object in the Redux state

        // Call the initialize session function if it exists or manage session reset
        yield call(initializeSessionAfterReconnection);
      } else if (action.type === 'WEBSOCKET_CLOSED') {
        yield put({ type: 'RECONNECT_WEBSOCKET' });
      } else {
        yield put(action); // Handle other actions from the eventChannel
      }
    }
  } catch (error) {
    console.error('[WebSocket] Reconnection failed:', error);
    yield put(displayErrorMessage(`WebSocket reconnection failed: ${error.message}`));
  }
}



function* initializeSessionAfterReconnection() {

  const currentSessionId = yield select((state) => state.chat.userLearningSessionID);

  if (!currentSessionId) {
    return;
  }
  yield put({ type: 'START_LEARNING_SESSION', payload: currentSessionId });

  // Log additional state details if needed
}


// Helper function to wait for WebSocket to be open
function waitForSocketToBeOpen(socket) {
  return new Promise((resolve) => {
    if (socket.readyState === WebSocket.OPEN) {
      resolve();
    } else {
      socket.onopen = () => {
        resolve();
      };
    }
  });
}

function* initiateStartSession(action) {
  const currentSessionId = yield select(getSessionId);
  const sessionId = action.payload;

  if (currentSessionId === sessionId) {
    // If the session IDs are identical, return early
    console.log('Session IDs are identical. No action needed.');
    return;
  }
  if (currentSessionId) {
    yield call(pauseCurrentSession);
  }

  // Dispatch the START_LEARNING_SESSION action after pausing the current session
  yield put({ type: START_LEARNING_SESSION, payload: sessionId });

  const updatedSessionId = yield select(getSessionId);
  console.log('Updated session ID after starting new session:', updatedSessionId);
}


function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default function* chatSaga() {
  yield all([
    takeEvery(SEND_MESSAGE, handleSendMessage),
    takeEvery(RECEIVE_MESSAGE, watchReceiveMessage),
    takeEvery(START_CALIBRATION_SESSION, startCalibrationSessionSaga),
    takeEvery(INITIATE_START_SESSION, initiateStartSession),
    takeEvery(START_LEARNING_SESSION, sendStartSessionMessage),
    fork(initializeWebSocket),
    fork(processMessageQueue),
    takeEvery(SEND_RESUME_SESSION, sendResumeSessionMessage),
    fork(watchReconnectWebSocket), 
  ]);
}
