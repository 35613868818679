// Converts a mastery level to a numerical value
export function convertMasteryLevel(masteryLevel) {
  switch (masteryLevel) {
    case 1:
    case 2:
      return 0;
    case 3:
      return 0.75;
    case 4:
      return 1;
    default:
      return 0;
  }
}

export function calculateAverageMasteryLevel(items) {
  let totalMasteryLevel = 0;
  let itemCount = 0;

  const traverse = (node) => {
    // If node is an object and has a mastery_level, it's a leaf node.
    if (node && typeof node === 'object' && 'mastery_level' in node) {
      totalMasteryLevel += convertMasteryLevel(node.mastery_level);
      itemCount++;
    } else if (node && typeof node === 'object') {
      // If node is an object but not a leaf node, iterate over its properties.
      Object.values(node).forEach(childNode => {
        // Recursively traverse children, which could be either arrays or objects.
        traverse(childNode);
      });
    }
    // Arrays are directly traversed.
    else if (Array.isArray(node)) {
      node.forEach(childNode => traverse(childNode));
    }
  };

  // Start traversing from the root node.
  traverse(items);

  // Return null if no leaf nodes are found to indicate no calculation could be made.
  if (itemCount === 0) {
    return null;
  }

  // Otherwise, return the average mastery level.
  return totalMasteryLevel / itemCount;
}



// Converts a mastery level to a text description
export function convertMasteryLevelToText(masteryLevel) {
  switch (masteryLevel) {
    case 1:
      return "Not yet";
    case 2:
      return "Ready to learn";
    case 3:
      return "Good, needs reinforcement";
    case 4:
      return "Learnt";
    default:
      return "N/ABB";
  }
}

// Converts the raw API data to the expected format for rendering
export function convertDataToExpectedFormat(data) {
  function convertInnerData(item, isRoot = false, rootName = "") {
    const children = [];

    for (const key in item) {
      const value = item[key];
      if (typeof value === "object" && !value.hasOwnProperty("mastery_level")) {
        const convertedChild = convertInnerData(value, false);
        if (value.name) {
          convertedChild.name = value.name;
        } else if (!isRoot) {
          // Merge the keys with their children if they don't have a name, and it's not the root level
          children.push(...convertedChild.children);
          continue;
        }
        children.push(convertedChild);
      } else if (value.hasOwnProperty("mastery_level")) {
        children.push(value);
      }
    }

    return { name: isRoot ? rootName : item.name, children: children };
  }

  // Handle the root level differently by passing `true` as the second argument
  const rootItem = { name: data.name, ...data.subjects };
  return [convertInnerData(rootItem, true, rootItem.name)];
}