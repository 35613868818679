import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const calculateRemainingTime = (endTime) => {
  const now = new Date();
  const end = new Date(endTime);
  return Math.max(0, Math.floor((end - now) / 1000));
};

const ActivityTimer = () => {
  const { endTime, show } = useSelector(state => state.timer.activityTimer);
  const [remainingTime, setRemainingTime] = useState(() => calculateRemainingTime(endTime));

  useEffect(() => {
    if (endTime) {
      const updateRemainingTime = () => {
        setRemainingTime(calculateRemainingTime(endTime));
      };

      updateRemainingTime(); // Immediately update remaining time
      const interval = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(interval);
    }
  }, [endTime]);

  if (!show) return null;

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  return (
    <div className="activity-timer">
      {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
    </div>
  );
};

export default ActivityTimer;
