// TableComponent.js
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  Box,
  Button,
  Icon,
  Grid,
  GridItem,
  Text,
  Flex,
  Input,
  VStack,
  IconButton,
  Link,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import {
  AddIcon,
  ArrowBackIcon,
  ChevronRightIcon,
  LockIcon,
} from '@chakra-ui/icons';
import {
  fetchLearnerProfileData,
  fetchSearchSuggestions,
  fetchSearchSuggestionsSuccess,
  addUserCourse,
  showAddCourseForm,
  hideAddCourseForm,
  checkLockedStatus,
} from '../../redux/actions/learnerProfileActions';
import {
  convertDataToExpectedFormat,
  convertMasteryLevelToText,
  calculateAverageMasteryLevel,
} from './utils';
import { createCheckoutSessionApi } from '../../utils/api';
import {
  startLearningSession,
  startCalibrationSession,
} from '../../redux/actions/chatActions';
import { closeAllComponents } from '../../redux/actions/headerActions';

const TableComponent = ({ onAddCourseClick }) => {
  const dispatch = useDispatch();
  const showForm = useSelector(
    (state) => state.learnerProfile.showAddCourseForm
  );

  // Updated colors to match the light design
  const bgColor = 'lightGrayishWhite';
  const cardColor = 'backgroundWhite';
  const textColor = 'darkGray.500';
  const secondaryTextColor = 'primary.500'; // Use primary color for emphasis
  const borderColor = 'lightGray.500';

  const Courses = useSelector((state) => state.learnerProfile.data);
  const [currentLevelData, setCurrentLevelData] = useState(
    Object.keys(Courses ?? {}).length ? [Courses] : []
  );

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const searchSuggestions = useSelector(
    (state) => state.learnerProfile.searchSuggestions
  );
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  // Ensure currentLevelData is updated correctly when Courses changes
  useEffect(() => {
    if (Courses && Courses.name) {
      setCurrentLevelData([Courses]);
    }
  }, [Courses]);

  // Handlers and utility functions
  const handleShowForm = () => {
    dispatch(showAddCourseForm());
  };

  const handleHideForm = () => {
    dispatch(hideAddCourseForm());
    setSearchQuery('');
  };

  // Create a debounced function for fetching search suggestions
  const debouncedFetchSuggestions = useCallback(
    debounce((query) => {
      if (query) {
        dispatch(fetchSearchSuggestions(query));
      } else {
        dispatch(fetchSearchSuggestionsSuccess([]));
      }
    }, 300),
    [dispatch]
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query); // Update state immediately
    debouncedFetchSuggestions(query); // Debounced API call
  };

  const selectSuggestion = (suggestion) => {
    setSearchQuery(suggestion.name);
    setSelectedCourseId(suggestion.id);
    dispatch(fetchSearchSuggestionsSuccess([]));
  };

  const handleSubmit = () => {
    if (selectedCourseId) {
      dispatch(addUserCourse(selectedCourseId));
      dispatch(hideAddCourseForm());
      setSearchQuery('');
    }
  };

  const handleItemClick = (item) => {
    if (item.children) {
      setCurrentLevelData(item.children);
      setBreadcrumbPath([...breadcrumbPath, item.name]);
    }
  };

  const handleBreadcrumbClick = (crumb, index) => {
    if (index === 0) {
      setCurrentLevelData([Courses]);
      setBreadcrumbPath([]);
    } else {
      const newPath = breadcrumbPath.slice(0, index + 1);
      setBreadcrumbPath(newPath);
      // Logic to update currentLevelData based on new breadcrumb path
      let data = Courses;
      for (let i = 0; i <= index; i++) {
        const name = newPath[i];
        data = data.children.find((child) => child.name === name);
      }
      setCurrentLevelData(data.children);
    }
  };

  const handleCalibrateClick = (courseId) => {
    dispatch(startCalibrationSession(courseId));
    dispatch(closeAllComponents());
  };

  const handleStudyClick = (topicId) => {
    dispatch(startLearningSession(topicId));
    dispatch(closeAllComponents());
  };

  const handleUpgradeNow = async (courseId) => {
    const response = await createCheckoutSessionApi(courseId);
    if (response && response.url) {
      window.location.href = response.url;
    }
  };

  return (
    <Box
      className="table-container"
      p={4}
      bg={bgColor}
      borderRadius="md"
      width="50vw"
      maxHeight="70vh"
      overflowY="auto"
      display="flex"
      flexDirection="column"
      boxShadow="lg"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        {breadcrumbPath.length > 0 ? (
          <Breadcrumb
            spacing="8px"
            separator={<ChevronRightIcon color="mediumGray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink
                as="span"
                cursor="pointer"
                onClick={() => handleBreadcrumbClick(null, 0)}
                isTruncated
                maxWidth="120px"
                color={secondaryTextColor}
                fontWeight="bold"
              >
                Learner Profile
              </BreadcrumbLink>
            </BreadcrumbItem>
            {breadcrumbPath.map((crumb, index) => (
              <BreadcrumbItem
                key={index}
                isCurrentPage={index === breadcrumbPath.length - 1}
              >
                <BreadcrumbLink
                  as="span"
                  cursor="pointer"
                  onClick={() => handleBreadcrumbClick(crumb, index + 1)}
                  isTruncated
                  maxWidth="120px"
                  color={
                    index === breadcrumbPath.length - 1
                      ? textColor
                      : secondaryTextColor
                  }
                  fontWeight={index === breadcrumbPath.length - 1 ? 'bold' : 'normal'}
                >
                  {crumb}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        ) : (
          <Text fontSize="lg" fontWeight="bold" color={textColor}>
            Learner Profile
          </Text>
        )}
        {!showForm && (
          <Button
            colorScheme="primary"
            variant="ghost"
            onClick={handleShowForm}
            _hover={{ bg: 'lightPeach.500' }}
          >
            <Icon as={AddIcon} w={4} h={4} color={secondaryTextColor} />
          </Button>
        )}
      </Flex>
      {showForm ? (
        <>
          <IconButton
            icon={<ArrowBackIcon w={6} h={6} />}
            colorScheme="primary"
            variant="ghost"
            aria-label="Back"
            position="absolute"
            top="0"
            left="1"
            size="md"
            onClick={handleHideForm}
          />
          <VStack spacing={4} position="relative">
            <Input
              placeholder="Type to search..."
              value={searchQuery}
              onChange={handleSearchChange}
              bg={cardColor}
              color={textColor}
              borderColor={borderColor}
              _placeholder={{ color: 'mediumGray.500' }}
            />
            {searchSuggestions.length > 0 && (
              <Box
                position="absolute"
                mt={10}
                width="100%"
                zIndex="10"
                bg={cardColor}
                border="1px"
                borderColor={borderColor}
                borderRadius="md"
              >
                {searchSuggestions.map((suggestion) => (
                  <Box
                    key={suggestion.id}
                    onClick={() => selectSuggestion(suggestion)}
                    p={2}
                    cursor="pointer"
                    _hover={{ bg: 'lightPeach.500' }}
                    color={textColor}
                  >
                    {suggestion.name}
                  </Box>
                ))}
              </Box>
            )}
            <Button
              colorScheme="primary"
              variant="solid"
              onClick={handleSubmit}
              _hover={{ bg: 'lightPeach.500' }}
            >
              Confirm
            </Button>
          </VStack>
        </>
      ) : (
        <>
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap={4}
            bg={cardColor}
            p={2}
            borderRadius="md"
            border="1px"
            borderColor={borderColor}
          >
            <GridItem colSpan={1}>
              <Text fontWeight="bold" color={textColor}>
                Course Name
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text fontWeight="bold" color={textColor}>
                Progress
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text fontWeight="bold" color={textColor}>
                Actions
              </Text>
            </GridItem>
          </Grid>
          {currentLevelData.length === 0 ? (
            <Flex
              justifyContent="center"
              alignItems="center"
              py={5}
              color={textColor}
            >
              <Text>No courses added yet.</Text>
              <Link
                color={secondaryTextColor}
                onClick={handleShowForm}
                fontWeight="bold"
                ml={1}
              >
                Add a course
              </Link>
            </Flex>
          ) : (
            currentLevelData.map((item, index) => (
              <Box
                key={index}
                p={2}
                mb={2}
                cursor="pointer"
                borderWidth="1px"
                borderColor={borderColor}
                borderRadius="md"
                bg={cardColor}
                _hover={{ bg: 'lightPeach.500' }}
                onClick={() => handleItemClick(item)}
              >
                <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                  <GridItem colSpan={1}>
                    <Text color={textColor}>{item.name}</Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text color={textColor}>
                      {item.hasOwnProperty('mastery_level')
                        ? convertMasteryLevelToText(item.mastery_level)
                        : `${(
                            calculateAverageMasteryLevel(item) * 100
                          ).toFixed(0)}%`}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    {breadcrumbPath.length === 0 &&
                    item.calibration &&
                    !item.calibrated ? (
                      <Button
                        size="sm"
                        colorScheme="primary"
                        variant="solid"
                        _hover={{ bg: 'lightPeach.500' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCalibrateClick(item.id);
                        }}
                      >
                        Calibrate
                      </Button>
                    ) : item.hasOwnProperty('mastery_level') ? (
                      item.locked ? (
                        <Button
                          size="sm"
                          colorScheme="primary"
                          variant="solid"
                          _hover={{ bg: 'lightPeach.500' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpgradeNow(item.id);
                          }}
                          leftIcon={<LockIcon />}
                        >
                          Upgrade
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          colorScheme="primary"
                          variant="solid"
                          _hover={{ bg: 'lightPeach.500' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStudyClick(item.id);
                          }}
                        >
                          Study
                        </Button>
                      )
                    ) : (
                      <Text color={textColor}>-</Text>
                    )}
                  </GridItem>
                </Grid>
              </Box>
            ))
          )}
        </>
      )}
    </Box>
  );
};

export default TableComponent;

