import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  Flex,
  Image,
  SimpleGrid,
  VStack,
  Stack,
  HStack,
  Icon,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  FaCheckCircle,
  FaAdjust,
  FaDollarSign,
  FaClock,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Global } from '@emotion/react';
import logo from '../assets/images/Logo.png';
import heroImage from '../assets/images/landing_page_graphic.png';

// Motion-enhanced Box for animations
const MotionBox = motion(Box);

// SectionWrapper Component with adjusted justifyContent
const SectionWrapper = ({ children, bg, color, minH, forwardedRef }) => {
  const controls = useAnimation();
  const [sectionRef, inView] = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <MotionBox
      as="section"
      ref={forwardedRef || sectionRef}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
        hidden: { opacity: 0, y: 20 },
      }}
      bg={bg || '#FFFFFF'}
      color={color || '#333333'}
      minH={minH || 'auto'}
      width="100%"
      display="flex"
      flexDirection="column"
      // Adjust justifyContent based on viewport size
      justifyContent={{ base: 'flex-start', md: 'space-between' }}
      sx={{ scrollMarginTop: '80px' }}
      overflowX="hidden" // Prevent horizontal overflow
    >
      {children}
    </MotionBox>
  );
};

// SectionContent Component adjusted for vertical centering
const SectionContent = ({ children }) => (
  <Flex
    maxW={{ base: '100%', md: '1200px' }}
    mx="auto"
    px={{ base: 2, md: 8 }}
    py={{ base: 8, md: 12 }}
    flex="1"
    pt={{ base: '100px', md: '80px' }}
    pb={{ base: '80px', md: '40px' }}
    position="relative"
    overflowX="hidden"
    flexDirection="column"
    justifyContent={{ base: 'flex-start', md: 'center' }} // Center content vertically on desktop
    alignItems="center"
  >
    {children}
  </Flex>
);

// ValueCard Component remains the same
const ValueCard = ({ icon, title, bullets }) => (
  <Stack spacing={4} align="flex-start" textAlign="left">
    <Icon as={icon} boxSize="40px" color="#E57368" />
    <Heading as="h3" size="md" textAlign="left" color="#333333">
      {title}
    </Heading>
    <UnorderedList fontSize="md" color="#333333" spacing={2}>
      {bullets.map((bullet, index) => (
        <ListItem key={index}>{bullet}</ListItem>
      ))}
    </UnorderedList>
  </Stack>
);

// TestimonialCard Component remains the same
const TestimonialCard = ({ text, author }) => (
  <Box
    bg="#FFFFFF"
    borderRadius="md"
    boxShadow="md"
    p={6}
    textAlign="left"
    h="100%"
  >
    <Text fontSize="md" mb={4} color="#333333">
      {text}
    </Text>
    <Text fontSize="sm" color="#666666" fontWeight="bold">
      — {author}
    </Text>
  </Box>
);

// TestimonialsCarousel Component remains the same
const TestimonialsCarousel = ({ testimonials }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialsToShow = 3; // Number of testimonials to display at a time
  const length = testimonials.length;

  const nextSlide = () => {
    setCurrentIndex((currentIndex + testimonialsToShow) % length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (currentIndex - testimonialsToShow + length) % length
    );
  };

  const displayedTestimonials = [];

  for (let i = 0; i < testimonialsToShow; i++) {
    const index = (currentIndex + i) % length;
    displayedTestimonials.push(testimonials[index]);
  }

  return (
    <Box
      position="relative"
      maxW={{ base: '100%', md: '1000px' }}
      mx="auto"
      overflow="hidden"
    >
      <Flex
        align="center"
        justify="center"
        wrap={{ base: 'wrap', md: 'nowrap' }}
      >
        {/* Left arrow */}
        <Icon
          as={FaChevronLeft}
          onClick={prevSlide}
          boxSize="30px"
          color="#FF7043"
          cursor="pointer"
          _hover={{ color: '#E57368' }}
          aria-label="Previous testimonials"
          mr={4}
          display={{ base: 'none', md: 'block' }} // Hide on mobile
        />
        {/* Testimonials */}
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} flex="1" w="100%">
          {displayedTestimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              text={testimonial.text}
              author={testimonial.author}
            />
          ))}
        </SimpleGrid>
        {/* Right arrow */}
        <Icon
          as={FaChevronRight}
          onClick={nextSlide}
          boxSize="30px"
          color="#FF7043"
          cursor="pointer"
          _hover={{ color: '#E57368' }}
          aria-label="Next testimonials"
          ml={4}
          display={{ base: 'none', md: 'block' }} // Hide on mobile
        />
      </Flex>
    </Box>
  );
};

// PlanCard Component remains the same
const PlanCard = ({ planName, duration, weeklyHours, price }) => (
  <Box
    border="1px solid"
    borderColor="#E0E0E0"
    borderRadius="md"
    p={6}
    textAlign="left"
    bg="#FFFFFF"
    boxShadow="sm"
    color="#333333"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    minH="200px"
  >
    <Box>
      <Heading as="h3" size="lg" mb={4} color="#333333" fontWeight="bold">
        {planName}
      </Heading>
      <Stack spacing={3}>
        <HStack spacing={3}>
          <Icon as={FaCheckCircle} color="#FF7043" boxSize="5" />
          <Text fontSize="md" color="#333333" fontWeight="medium">
            Duration: {duration}
          </Text>
        </HStack>
        <HStack spacing={3}>
          <Icon as={FaCheckCircle} color="#FF7043" boxSize="5" />
          <Text fontSize="md" color="#333333" fontWeight="medium">
            Weekly Hours: {weeklyHours}
          </Text>
        </HStack>
        <HStack spacing={3}>
          <Icon as={FaCheckCircle} color="#FF7043" boxSize="5" />
          <Text fontSize="md" color="#333333" fontWeight="medium">
            Price: {price}
          </Text>
        </HStack>
      </Stack>
    </Box>
  </Box>
);

// Main MarketingPage Component
const MarketingPage = () => {
  const navigate = useNavigate();
  const nextSectionRef = useRef(null);

  // Testimonials data
  const testimonials = [
    {
      text: '“The AI tutor helped me raise my score by 150 points in just a month!”',
      author: 'Sarah P.',
    },
    {
      text: '“The personalized lessons made a huge difference—I felt totally prepared for the SAT.”',
      author: 'John K.',
    },
    {
      text: '“My SAT score improved dramatically thanks to the custom study sessions.”',
      author: 'Emily S.',
    },
    {
      text: '“Flow Learn\'s AI tutor was always there when I needed it. It felt like a real tutor!”',
      author: 'Michael B.',
    },
    {
      text: '“Affordable and effective. I saved money and improved my score significantly.”',
      author: 'Lisa M.',
    },
  ];

  // Handlers for navigation
  const handleSignUp = () => {
    navigate('/register');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  // Function to scroll to the next section smoothly
  const scrollToNextSection = () => {
    if (nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // State to control visibility of the arrow section
  const [showArrow, setShowArrow] = useState(true);

  // Use Intersection Observer to detect if the next section is in view
  const [ref, inView] = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      setShowArrow(false);
    } else {
      setShowArrow(true);
    }
  }, [inView]);

  // Height of the header to calculate viewport height
  const headerHeight = 80; // Adjust if your header height changes

  return (
    <>
      {/* Global Styles */}
      <Global
        styles={`
          html {
            scroll-snap-type: y proximity;
            height: 100%;
          }
          body {
            margin: 0;
            padding: 0;
            overflow-y: scroll;
            scroll-behavior: smooth;
            background-color: #FFFFFF;
            height: 100%;
          }
          section {
            scroll-snap-align: start;
          }
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(5px);
            }
            60% {
              transform: translateY(3px);
            }
          }
        `}
      />

      {/* Header */}
      <Flex
        as="header"
        justify="space-between"
        align="center"
        w="100%"
        p={4}
        bg="#F5F5F5"
        position="fixed"
        top="0"
        zIndex="10"
        boxShadow="sm"
        height={`${headerHeight}px`}
      >
        {/* Logo */}
        <Image
          src={logo}
          alt="Flow Learn logo"
          htmlWidth="150px"
          objectFit="contain"
          cursor="pointer"
          onClick={() => navigate('/')}
        />
        {/* Buttons */}
        <Flex align="center">
          <Button
            variant="outline"
            borderColor="#FF7043"
            color="#FF7043"
            backgroundColor="transparent"
            _hover={{ bg: '#FFEDE6' }}
            size="md"
            mr={4}
            onClick={handleSignUp}
          >
            Start trial
          </Button>
          <Text
            cursor="pointer"
            onClick={handleLogin}
            fontSize="md"
            color="#FF7043"
          >
            Log in
          </Text>
        </Flex>
      </Flex>

      {/* Main Content */}
      <Box pt={`${headerHeight}px`} bg="#FFFFFF" overflowX="hidden">
        {/* Hero Section */}
        <SectionWrapper
          bg="#FFFFFF"
          color="#333333"
          minH={{ base: 'auto', md: `calc(100vh - ${headerHeight}px)` }} // Full viewport height on desktop
        >
          <SectionContent>
            {/* Main Content */}
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justify={{ base: 'center', md: 'space-between' }}
              align="center"
              w="100%"
              flex="1"
              px={{ base: 2, md: 8 }}
              flexWrap="wrap"
              overflowX="hidden"
            >
              {/* Left: Text Content */}
              <Box
                flex="1"
                minW="0"
                pr={{ base: 0, md: 8 }}
                mb={{ base: 8, md: 0 }}
                textAlign={{ base: 'center', md: 'left' }}
                w="100%"
              >
                {/* Title */}
                <Heading
                  as="h1"
                  mb={4}
                  fontSize={{ base: '2xl', md: '5xl' }}
                  color="#E57368"
                  whiteSpace="normal"
                  textAlign={{ base: 'center', md: 'left' }}
                >
                  Ace the SATs with Flow Learn's AI Tutor
                </Heading>
                {/* Guarantee Line */}
                <Text fontSize="md" color="#333333" mb={4}>
                  Score{' '}
                  <Text as="span" color="#FF7043">
                    1400+
                  </Text>{' '}
                  or your money back*
                </Text>
                {/* Call to Action Button */}
                <Button
                  variant="outline"
                  borderColor="#FF7043"
                  color="#FF7043"
                  backgroundColor="transparent"
                  _hover={{ bg: '#FFEDE6' }}
                  size="lg"
                  onClick={handleSignUp}
                  mb={4}
                  whiteSpace="normal"
                  textAlign="center"
                >
                  Get full practice test + 5 hours tutoring FREE
                </Button>
                <Text fontSize="sm" color="#333333">
                  No card or commitment needed.
                </Text>
              </Box>

              {/* Right: Hero Image */}
              <Box
                flex="1"
                minW="0"
                textAlign="center"
                w="100%"
                mt={{ base: 8, md: 0 }}
              >
                <Image
                  src={heroImage}
                  alt="AI-driven SAT prep"
                  width="100%"
                  height="auto"
                  objectFit="contain"
                  borderRadius="md"
                  boxShadow="lg"
                  maxW="100%"
                />
              </Box>
            </Flex>

            {/* Arrow Section */}
            <Box
              mt={{ base: 8, md: 'auto' }}
              mb={{ base: 0, md: 4 }}
              textAlign="center"
              w="100%"
            >
              {showArrow && (
                <VStack spacing={4} align="center">
                  <Heading
                    as="h2"
                    size="lg"
                    color="#FF857A"
                  >
                    Why Flow Learn
                  </Heading>
                  <Text fontSize="md" color="#333333">
                    Continuously tailored. Affordable. Always available.
                  </Text>
                  <Icon
                    as={FaChevronDown}
                    boxSize="8"
                    color="#FF7043"
                    cursor="pointer"
                    onClick={scrollToNextSection}
                    animation="bounce 2s infinite"
                    _hover={{ color: '#E57368' }}
                    aria-label="Scroll to next section"
                  />
                </VStack>
              )}
            </Box>
          </SectionContent>
        </SectionWrapper>

        {/* Next Section Reference */}
        <Box ref={nextSectionRef} />

        {/* Combined "Why Flow Learn" and "Proven Progress" Section */}
        <SectionWrapper bg="#F5F5F5" color="#333333" ref={ref}>
          <SectionContent>
            <Heading
              as="h2"
              size="xl"
              mb={8}
              textAlign="center"
              color="#FF857A"
            >
              Why Flow Learn
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mb={12}>
              <ValueCard
                icon={FaAdjust}
                title="Continuously tailored"
                bullets={[
                  'Flow Learn tracks and adapts to your needs every second.',
                  'Every question, sequence, and schedule is personalized.',
                  'No recycled questions or waiting—just uninterrupted growth.',
                ]}
              />
              <ValueCard
                icon={FaDollarSign}
                title="3% of the cost"
                bullets={[
                  'Flow Learn: $2.50/hour',
                  'Traditional tutoring: $75/hour.',
                ]}
              />
              <ValueCard
                icon={FaClock}
                title="Always available"
                bullets={[
                  'Skip the scheduling and endless self-study.',
                  'With Flow Learn, your AI is always available, personalized.',
                ]}
              />
            </SimpleGrid>

            {/* Testimonials */}
            <Heading
              as="h2"
              size="xl"
              mb={8}
              textAlign="center"
              color="#FF857A"
            >
              Proven progress
            </Heading>
            <Box mb={12}>
              <TestimonialsCarousel testimonials={testimonials} />
            </Box>
          </SectionContent>
        </SectionWrapper>

        {/* Combined "Select Your Plan" and "Money-back Guarantee" Section */}
        <SectionWrapper bg="#FFFFFF" color="#333333">
          <SectionContent>
            <Heading
              as="h2"
              size="xl"
              mb={8}
              textAlign="center"
              color="#FF857A"
            >
              Select your plan
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mb={12}>
              <PlanCard
                planName="Standard"
                duration="24 weeks"
                weeklyHours="5"
                price="$99/month ($594 total)"
              />
              <PlanCard
                planName="Intensive"
                duration="12 weeks"
                weeklyHours="10"
                price="$198/month ($594 total)"
              />
              <PlanCard
                planName="Crashcourse"
                duration="6 weeks"
                weeklyHours="20"
                price="$594"
              />
            </SimpleGrid>

            {/* Money-back guarantee */}
            <Box mt={12}>
              <Heading
                as="h2"
                size="lg"
                mb={4}
                textAlign="center"
                color="#FF857A"
              >
                Money-back Guarantee
              </Heading>
              <Box h="2px" bg="#E0E0E0" my={4} mx="auto" maxW="100px" />
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                align="flex-start"
                maxW="800px"
                mx="auto"
                mt={6}
              >
                {/* Left Column: Guarantee Text */}
                <Box flex="1" pr={{ md: 4 }}>
                  <Text
                    mb={4}
                    fontSize="md"
                    textAlign="left"
                    color="#333333"
                  >
                    We're confident in your success. If you don’t meet the following score
                    improvement thresholds, we’ll refund your tuition (minus
                    third-party fees):
                  </Text>
                  <Text fontSize="md" textAlign="left" color="#333333">
                    <strong>1. Start below 1250:</strong> Gain 150+ points.
                    <br />
                    <strong>2. Start at 1250+:</strong> Achieve 1400+.
                  </Text>
                </Box>
                {/* Right Column: How it works */}
                <Box flex="1" pl={{ md: 4 }} mt={{ base: 8, md: 0 }}>
                  <Heading
                    as="h3"
                    size="md"
                    mb={4}
                    textAlign="left"
                    color="#FF857A"
                    fontWeight="bold"
                  >
                    How it works:
                  </Heading>
                  <VStack spacing={3} align="start">
                    <HStack align="start" spacing={2}>
                      <Icon as={FaCheckCircle} color="#FF7043" mt={1} />
                      <Text fontSize="md" color="#333333">
                        Set your baseline with an official SAT/PSAT score or
                        PrepMI Practice Test.
                      </Text>
                    </HStack>
                    <HStack align="start" spacing={2}>
                      <Icon as={FaCheckCircle} color="#FF7043" mt={1} />
                      <Text fontSize="md" color="#333333">
                        Complete all 120 hrs of learning sessions.
                      </Text>
                    </HStack>
                    <HStack align="start" spacing={2}>
                      <Icon as={FaCheckCircle} color="#FF7043" mt={1} />
                      <Text fontSize="md" color="#333333">
                        Meet one of the thresholds—or get your money back.
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
              </Flex>
            </Box>
          </SectionContent>
        </SectionWrapper>

        {/* Footer CTA Section */}
        <SectionWrapper bg="#F5F5F5" color="#333333" minH="auto">
          <SectionContent>
            <Flex
              direction="column"
              justify="center"
              align="center"
              textAlign="center"
              flex="1"
            >
              <Heading
                as="h2"
                mb={4}
                fontSize={{ base: 'xl', md: '2xl' }}
                color="#FF857A"
              >
                Give it a try! No commitment or card details needed.
              </Heading>
              <Button
                variant="outline"
                borderColor="#FF7043"
                color="#FF7043"
                backgroundColor="transparent"
                _hover={{ bg: '#FFEDE6' }}
                size="lg"
                onClick={handleSignUp}
              >
                Start free trial
              </Button>
            </Flex>
          </SectionContent>
        </SectionWrapper>
      </Box>
    </>
  );
};

export default MarketingPage;
