// Header.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Flex,
  Box,
  Image,
  IconButton,
  Text,
  VStack,
  Link,
  Icon,
  Button,
  Spacer,
} from '@chakra-ui/react';
import { CalendarIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import logo from '../../assets/images/Logo.png';
import LearnerProfile from '../LearnerProfile/LearnerProfile';
import LearningSchedule from '../LearningSchedule/LearningSchedule';
import ActivityTimer from '../Timers/ActivityTimer';
import SessionTimer from '../Timers/SessionTimer';
import { toggleNotepad } from '../../redux/actions/notepadActions';
import {
  toggleLearnerProfile,
  toggleLearningSchedule,
  toggleAccount,
} from '../../redux/actions/headerActions';

const UserInitialIcon = () => (
  <Box
    borderRadius="full"
    border="2px"
    borderColor="primary.500"
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    w={8}
    h={8}
    bg="backgroundWhite"
    color="primary.500"
  >
    B
  </Box>
);

const Header = ({ onLogout, isNotepadVisible, style }) => {
  const dispatch = useDispatch();
  const bgColor = 'lightGrayishWhite'; // Updated to match marketing page's header background
  const iconColor = 'primary.500'; // Keep icons in primary color
  const textColor = 'darkGray.500'; // For text contrast

  const isLearnerProfileOpen = useSelector(
    (state) => state.header.isLearnerProfileOpen
  );
  const isLearningScheduleOpen = useSelector(
    (state) => state.header.isLearningScheduleOpen
  );
  const isAccountOpen = useSelector((state) => state.header.isAccountOpen);

  const handleToggleNotepad = () => {
    dispatch(toggleNotepad());
  };

  const handleToggleLearnerProfile = () => {
    dispatch(toggleLearnerProfile());
  };

  const handleToggleLearningSchedule = () => {
    dispatch(toggleLearningSchedule());
  };

  const handleToggleAccount = () => {
    dispatch(toggleAccount());
  };

  return (
    <Flex
      as="nav"
      style={style}
      align="center"
      justify="space-between"
      wrap="wrap"
      height="60px"
      bg={bgColor}
      boxShadow="sm"
      width="full"
      position="sticky"
      top="0"
      zIndex="sticky"
    >
      {/* Left-aligned items */}
      <Flex align="center" mr={5}>
        <RouterLink to="/">
          <Image
            src={logo}
            alt="Company logo"
            htmlWidth="150px"
            objectFit="contain"
            pl="10px"
          />
        </RouterLink>
      </Flex>

      {/* Center-aligned Activity Timer */}
      <Spacer />
      <ActivityTimer color={textColor} />
      <Spacer />

      {/* Right-aligned items */}
      <Box display="flex" alignItems="center">
        <SessionTimer color={textColor} />
        <IconButton
          icon={<CalendarIcon />}
          aria-label="Learning Schedule"
          variant="ghost"
          color={iconColor}
          fontSize="20px"
          marginRight="1"
          onClick={handleToggleLearningSchedule}
          _hover={{ bg: 'lightPeach.500' }}
        />

        <IconButton
          icon={<Icon as={FaUserCircle} />}
          aria-label="Learning Profile"
          variant="ghost"
          color={iconColor}
          fontSize="20px"
          marginRight="1"
          onClick={handleToggleLearnerProfile}
          _hover={{ bg: 'lightPeach.500' }}
        />

        <Box as="button" onClick={handleToggleAccount} marginRight="1">
          <UserInitialIcon />
        </Box>

        {!isNotepadVisible && (
          <IconButton
            icon={<ChevronLeftIcon />}
            aria-label="Toggle Notepad"
            variant="ghost"
            color={iconColor}
            fontSize="35px"
            onClick={handleToggleNotepad}
            opacity="0.5"
            _hover={{ bg: 'lightPeach.500' }}
          />
        )}
      </Box>

      {/* Popover components */}
      {isLearningScheduleOpen && (
        <VStack
          align="stretch"
          position="absolute"
          right="1.5rem"
          top="100%"
          p={4}
          bg={bgColor}
          boxShadow="md"
          borderRadius="md"
          zIndex="popover"
          minW="200px"
        >
          <LearningSchedule />
        </VStack>
      )}

      {isLearnerProfileOpen && (
        <VStack
          align="stretch"
          position="absolute"
          right="1.5rem"
          top="100%"
          p={4}
          bg={bgColor}
          boxShadow="md"
          borderRadius="md"
          zIndex="popover"
          minW="200px"
        >
          <LearnerProfile />
        </VStack>
      )}

      {isAccountOpen && (
        <VStack
          align="stretch"
          position="absolute"
          right="1.5rem"
          top="100%"
          p={4}
          bg={bgColor}
          boxShadow="md"
          borderRadius="md"
          zIndex="popover"
          minW="200px"
        >
          <Text fontSize="lg" fontWeight="bold" color={textColor}>
            My Account
          </Text>
          <Button colorScheme="primary" variant="solid" size="sm">
            Add Course
          </Button>
          <Text fontSize="sm" color={textColor}>
            Learning Summary: Placeholder
          </Text>
          <Link
            color={iconColor}
            onClick={onLogout}
            display="flex"
            alignItems="center"
            fontSize="sm"
          >
            <Icon as={FaSignOutAlt} marginRight="2" />
            Log Out
          </Link>
        </VStack>
      )}
    </Flex>
  );
};

export default Header;
