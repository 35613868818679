import {
  CREATE_FLASHCARD_REQUEST,
  CREATE_FLASHCARD_SUCCESS,
  CREATE_FLASHCARD_FAILURE,
  FETCH_FLASHCARDS_REQUEST,
  FETCH_FLASHCARDS_SUCCESS,
  FETCH_FLASHCARDS_FAILURE,
  RATE_FLASHCARD_REQUEST,
  RATE_FLASHCARD_SUCCESS,
  RATE_FLASHCARD_FAILURE,
} from '../actions/flashcardActions';

const initialState = {
  flashcards: [],
  loading: false,
  error: null,
};

const flashcardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_FLASHCARD_REQUEST:
    case FETCH_FLASHCARDS_REQUEST:
    case RATE_FLASHCARD_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_FLASHCARD_SUCCESS:
      return { ...state, loading: false, flashcards: [...state.flashcards, action.payload] };
    case FETCH_FLASHCARDS_SUCCESS:
      return { ...state, loading: false, flashcards: action.payload };
    case RATE_FLASHCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        flashcards: state.flashcards.map(flashcard =>
          flashcard.id === action.payload.id ? { ...flashcard, ...action.payload } : flashcard
        ),
      };
    case CREATE_FLASHCARD_FAILURE:
    case FETCH_FLASHCARDS_FAILURE:
    case RATE_FLASHCARD_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default flashcardReducer;