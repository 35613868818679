import { TOGGLE_NOTEPAD } from '../actions/notepadActions';

const initialState = {
  isVisible: false,
};

const notepadReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_NOTEPAD:
      return {
        ...state,
        isVisible: !state.isVisible,
      };
    default:
      return state;
  }
};

export default notepadReducer;
