import {
  TOGGLE_LEARNER_PROFILE,
  TOGGLE_LEARNING_SCHEDULE,
  TOGGLE_ACCOUNT,
  CLOSE_ALL_COMPONENTS,
} from '../actions/headerActions'; // Adjust the path as necessary

const initialHeaderState = {
  isLearnerProfileOpen: false,
  isLearningScheduleOpen: false,
  isAccountOpen: false,
};

const headerReducer = (state = initialHeaderState, action) => {
  switch (action.type) {
    case TOGGLE_LEARNER_PROFILE:
      return {
        ...state,
        isLearnerProfileOpen: !state.isLearnerProfileOpen,
        isLearningScheduleOpen: false,
        isAccountOpen: false,
      };
    case TOGGLE_LEARNING_SCHEDULE:
      return {
        ...state,
        isLearnerProfileOpen: false,
        isLearningScheduleOpen: !state.isLearningScheduleOpen,
        isAccountOpen: false,
      };
    case TOGGLE_ACCOUNT:
      return {
        ...state,
        isLearnerProfileOpen: false,
        isLearningScheduleOpen: false,
        isAccountOpen: !state.isAccountOpen,
      };
    case CLOSE_ALL_COMPONENTS:
      return {
        ...initialHeaderState,
      };
    default:
      return state;
  }
};

export default headerReducer;