import { 
  SEND_MESSAGE, 
  RECEIVE_MESSAGE, 
  MESSAGE_ERROR, 
  UPDATE_TYPING_STATUS, 
  ADD_MESSAGE_TO_QUEUE, 
  REMOVE_MESSAGE_FROM_QUEUE,
  SET_SOCKET,
  UPDATE_SESSION_ID,
  SET_WAITING_FOR_SERVER_RESPONSE,
  DISPLAY_MESSAGE,
  START_LEARNING_SESSION,
  LOAD_MESSAGE_HISTORY,
  DISPLAY_ERROR_MESSAGE,
  SEND_RESUME_SESSION,
  SEND_RESUME_SESSION_SUCCESS,
  SEND_RESUME_SESSION_FAILURE
} from '../actions/chatActions';

const initialState = {
  messages: [], // stores all messages
  messageQueue: [], // stores queued messages
  isTyping: false, // tracks typing status
  error: null, // stores any error information
  socket: null,
  userLearningSessionID: null,
  waitingForServerResponse: false, // New state property

};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {

    case START_LEARNING_SESSION:
      // Reset the chat state but keep the socket and other necessary parts
      return {
        ...state,
        messages: [],
        messageQueue: [],
        waitingForServerResponse: false,
        isTyping: false,
        userLearningSessionID: action.payload, // Update session ID if necessary
      };

    case LOAD_MESSAGE_HISTORY:
      console.log("[Reducer] Loading message history", action.payload);
      if (action.payload.length >= 2) { // Ensure there are at least 2 messages
        const filteredHistory = action.payload.slice(1); // Remove the first message
        return {
          ...state,
          messages: [...state.messages, ...filteredHistory] // Add the modified batch of messages
        };
      }
      return state;

    case RECEIVE_MESSAGE:
      console.log('Received message:', action.payload);
      return { 
        ...state, 
        isTyping: false, // Stop showing the typing indicator
      };

    case MESSAGE_ERROR:
      return { ...state, error: action.payload };

    case UPDATE_TYPING_STATUS:
      return { ...state, isTyping: action.payload };

    case SET_WAITING_FOR_SERVER_RESPONSE:
      return { ...state, waitingForServerResponse: action.payload };

    case DISPLAY_MESSAGE:
      console.log(`[Reducer] DISPLAY_MESSAGE with payload:`, action.payload);
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    
    case ADD_MESSAGE_TO_QUEUE:
      // Add messages to the queue, both from user and server
      return {
        ...state,
        messageQueue: [...state.messageQueue, action.payload],
      };

    case REMOVE_MESSAGE_FROM_QUEUE:
      // Remove the processed message from the queue
      return {
        ...state,
        messageQueue: state.messageQueue.slice(1),
      };

    case UPDATE_SESSION_ID:
      return { ...state, userLearningSessionID: action.payload };

    case SET_SOCKET:
      return { ...state, socket: action.payload };

    case DISPLAY_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    case SEND_RESUME_SESSION:
      return {
        ...state,
        messages: [],
        messageQueue: [],
        waitingForServerResponse: false,
        isTyping: false,
        userLearningSessionID: action.payload, // Update session ID if necessary
      };

    case SEND_RESUME_SESSION_SUCCESS:
      return {
        ...state,
        waitingForServerResponse: false,
      };

    case SEND_RESUME_SESSION_FAILURE:
      return {
        ...state,
        waitingForServerResponse: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default chatReducer;
