import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Image,
  Text,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { loginRequest } from '../redux/actions/authActions';
import logo from '../assets/images/Logo.png';

const Login = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/app/dashboard";

  // Theme colors
  const primaryColor = 'primary.500';
  const mutedRedColor = 'mutedRed.500';
  const textColor = 'darkGray.500'; // Matches Chat.js
  const placeholderColor = 'mediumGray.500'; // Matches Chat.js placeholders
  const lightGrayishWhite = 'lightGrayishWhite';
  const backgroundWhite = 'backgroundWhite';
  const lightPeach = 'lightPeach.500';

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate(from, { replace: true });
    }
  }, [auth.isAuthenticated, navigate, from]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginRequest(credentials));
  };

  return (
    <Flex align="center" justify="center" minH="100vh" bg={lightGrayishWhite}>
      <Box
        w="full"
        maxW="md"
        p={8}
        borderRadius="lg"
        bg={backgroundWhite}
        boxShadow="lg"
      >
        <Flex justifyContent="center" mb={6}>
          <Image src={logo} alt="Company Logo" objectFit="contain" h="50px" />
        </Flex>
        <form onSubmit={handleSubmit}>
          <FormControl id="email" mb={4}>
            <FormLabel color={textColor}>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={credentials.email}
              onChange={handleChange}
              bg="lightGray.500" // Keep the existing background color
              color={textColor} // Match Chat.js text color
              _placeholder={{ color: placeholderColor }} // Match Chat.js placeholder color
            />
          </FormControl>
          <FormControl id="password" mb={6}>
            <FormLabel color={textColor}>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              bg="lightGray.500" // Keep the existing background color
              color={textColor} // Match Chat.js text color
              _placeholder={{ color: placeholderColor }} // Match Chat.js placeholder color
            />
          </FormControl>
          <Button
            type="submit"
            color={backgroundWhite}
            bg={primaryColor}
            _hover={{ bg: lightPeach }}
            size="lg"
            fontSize="md"
            w="full"
          >
            Login
          </Button>
        </form>
        <Text color={textColor} mt={4} textAlign="center">
          <ChakraLink as={Link} to="/request-password-reset" color={primaryColor}>
            Forgot Password?
          </ChakraLink>
        </Text>
        <Text color={textColor} mt={6} textAlign="center">
          Don't have an account?{' '}
          <ChakraLink as={Link} to="/register" color={primaryColor}>
            Register here
          </ChakraLink>
        </Text>
        {auth.error && (
          <Text color={mutedRedColor} mt={4} textAlign="center">
            {auth.error}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default Login;
