// Action Types
export const CREATE_FLASHCARD_REQUEST = 'CREATE_FLASHCARD_REQUEST';
export const CREATE_FLASHCARD_SUCCESS = 'CREATE_FLASHCARD_SUCCESS';
export const CREATE_FLASHCARD_FAILURE = 'CREATE_FLASHCARD_FAILURE';

export const FETCH_FLASHCARDS_REQUEST = 'FETCH_FLASHCARDS_REQUEST';
export const FETCH_FLASHCARDS_SUCCESS = 'FETCH_FLASHCARDS_SUCCESS';
export const FETCH_FLASHCARDS_FAILURE = 'FETCH_FLASHCARDS_FAILURE';

export const RATE_FLASHCARD_REQUEST = 'RATE_FLASHCARD_REQUEST';
export const RATE_FLASHCARD_SUCCESS = 'RATE_FLASHCARD_SUCCESS';
export const RATE_FLASHCARD_FAILURE = 'RATE_FLASHCARD_FAILURE';

// Action Creators for Requests
export const createFlashcardRequest = flashcard => ({
  type: CREATE_FLASHCARD_REQUEST,
  payload: flashcard,
});

export const fetchFlashcardsRequest = () => ({
  type: FETCH_FLASHCARDS_REQUEST,
});

export const rateFlashcardRequest = (id, rating) => ({
  type: RATE_FLASHCARD_REQUEST,
  payload: { id, rating },
});

// Action Creators for Successes
export const createFlashcardSuccess = flashcard => ({
  type: CREATE_FLASHCARD_SUCCESS,
  payload: flashcard,
});

export const fetchFlashcardsSuccess = flashcards => ({
  type: FETCH_FLASHCARDS_SUCCESS,
  payload: flashcards,
});

export const rateFlashcardSuccess = flashcard => ({
  type: RATE_FLASHCARD_SUCCESS,
  payload: flashcard,
});

// Action Creators for Failures
export const createFlashcardFailure = error => ({
  type: CREATE_FLASHCARD_FAILURE,
  payload: error,
});

export const fetchFlashcardsFailure = error => ({
  type: FETCH_FLASHCARDS_FAILURE,
  payload: error,
});

export const rateFlashcardFailure = error => ({
  type: RATE_FLASHCARD_FAILURE,
  payload: error,
});
