export const TOGGLE_LEARNER_PROFILE = 'TOGGLE_LEARNER_PROFILE';
export const TOGGLE_LEARNING_SCHEDULE = 'TOGGLE_LEARNING_SCHEDULE';
export const TOGGLE_ACCOUNT = 'TOGGLE_ACCOUNT';
export const CLOSE_ALL_COMPONENTS = 'CLOSE_ALL_COMPONENTS';

export const toggleLearnerProfile = () => ({
  type: TOGGLE_LEARNER_PROFILE,
});

export const toggleLearningSchedule = () => ({
  type: TOGGLE_LEARNING_SCHEDULE,
});

export const toggleAccount = () => ({
  type: TOGGLE_ACCOUNT,
});

export const closeAllComponents = () => ({
  type: CLOSE_ALL_COMPONENTS,
});

