import React, { useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Image,
  Text,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/images/Logo.png'; // Ensure this path is correct

const RequestPasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  // Utilize theme colors
  const primaryColor = 'primary.500';
  const mutedRedColor = 'mutedRed.500';
  const textColor = 'darkGray.500'; // Matches Chat.js text color
  const placeholderColor = 'mediumGray.500'; // Matches Chat.js placeholders
  const lightGrayishWhite = 'lightGrayishWhite';
  const backgroundWhite = 'backgroundWhite';
  const lightPeach = 'lightPeach.500';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/request-password-reset`,
        { email },
        { withCredentials: true }
      );
      setMessage(response.data.message);
    } catch (error) {
      setError(error.response?.data?.detail || "An error occurred.");
    }
  };

  return (
    <Flex align="center" justify="center" minH="100vh" bg={lightGrayishWhite}>
      <Box
        w="full"
        maxW="md"
        p={8}
        borderRadius="lg"
        bg={backgroundWhite}
        boxShadow="lg"
      >
        <Flex justifyContent="center" mb={6}>
          <Image src={logo} alt="Company Logo" objectFit="contain" h="50px" />
        </Flex>
        <form onSubmit={handleSubmit}>
          <FormControl id="email" mb={4}>
            <FormLabel color={textColor}>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              bg="lightGray.500" // Keep the original background
              color={textColor} // Match Chat.js text color
              _placeholder={{ color: placeholderColor }} // Match Chat.js placeholder color
              required
            />
          </FormControl>
          <Button
            type="submit"
            color={backgroundWhite}
            bg={primaryColor}
            _hover={{ bg: lightPeach }}
            size="lg"
            fontSize="md"
            w="full"
          >
            Request Password Reset
          </Button>
        </form>
        {message && (
          <Text color="green.500" mt={4} textAlign="center">
            {message}
          </Text>
        )}
        {error && (
          <Text color={mutedRedColor} mt={4} textAlign="center">
            {error}
          </Text>
        )}
        <Text color={textColor} mt={6} textAlign="center">
          Remembered your password?{' '}
          <ChakraLink as={Link} to="/login" color={primaryColor}>
            Login here
          </ChakraLink>
        </Text>
      </Box>
    </Flex>
  );
};

export default RequestPasswordReset;
