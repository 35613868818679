// Action Types
export const FETCH_LEARNER_PROFILE_DATA = 'FETCH_LEARNER_PROFILE_DATA';
export const FETCH_LEARNER_PROFILE_DATA_SUCCESS = 'FETCH_LEARNER_PROFILE_DATA_SUCCESS';
export const FETCH_LEARNER_PROFILE_DATA_FAILURE = 'FETCH_LEARNER_PROFILE_DATA_FAILURE';

export const FETCH_SEARCH_SUGGESTIONS = 'FETCH_SEARCH_SUGGESTIONS';
export const FETCH_SEARCH_SUGGESTIONS_SUCCESS = 'FETCH_SEARCH_SUGGESTIONS_SUCCESS';
export const FETCH_SEARCH_SUGGESTIONS_FAILURE = 'FETCH_SEARCH_SUGGESTIONS_FAILURE';

export const ADD_USER_COURSE = 'ADD_USER_COURSE';
export const ADD_USER_COURSE_SUCCESS = 'ADD_USER_COURSE_SUCCESS';
export const ADD_USER_COURSE_FAILURE = 'ADD_USER_COURSE_FAILURE';

export const SHOW_ADD_COURSE_FORM = 'SHOW_ADD_COURSE_FORM';
export const HIDE_ADD_COURSE_FORM = 'HIDE_ADD_COURSE_FORM';

export const CHECK_LOCKED_STATUS = 'CHECK_LOCKED_STATUS';
export const CHECK_LOCKED_STATUS_SUCCESS = 'CHECK_LOCKED_STATUS_SUCCESS';
export const CHECK_LOCKED_STATUS_FAILURE = 'CHECK_LOCKED_STATUS_FAILURE';

// Action Creators
export const fetchLearnerProfileData = () => ({
  type: FETCH_LEARNER_PROFILE_DATA
});

export const fetchLearnerProfileDataSuccess = (data) => ({
  type: FETCH_LEARNER_PROFILE_DATA_SUCCESS,
  payload: data
});

export const fetchLearnerProfileDataFailure = (error) => ({
  type: FETCH_LEARNER_PROFILE_DATA_FAILURE,
  payload: error
});

export const fetchSearchSuggestions = (query) => ({
  type: FETCH_SEARCH_SUGGESTIONS,
  payload: query
});

export const fetchSearchSuggestionsSuccess = (suggestions) => ({
  type: FETCH_SEARCH_SUGGESTIONS_SUCCESS,
  payload: suggestions
});

export const fetchSearchSuggestionsFailure = (error) => ({
  type: FETCH_SEARCH_SUGGESTIONS_FAILURE,
  payload: error
});

export const addUserCourse = (courseData) => ({
  type: ADD_USER_COURSE,
  payload: courseData
});

export const addUserCourseSuccess = (response) => ({
  type: ADD_USER_COURSE_SUCCESS,
  payload: response
});

export const addUserCourseFailure = (error) => ({
  type: ADD_USER_COURSE_FAILURE,
  payload: error
});

export const showAddCourseForm = () => ({
  type: SHOW_ADD_COURSE_FORM
});

export const hideAddCourseForm = () => ({
  type: HIDE_ADD_COURSE_FORM
});

export const checkLockedStatus = (courseId, objectiveId) => ({
  type: CHECK_LOCKED_STATUS,
  payload: { courseId, objectiveId },
});

export const checkLockedStatusSuccess = (status) => ({
  type: CHECK_LOCKED_STATUS_SUCCESS,
  payload: status,
});

export const checkLockedStatusFailure = (error) => ({
  type: CHECK_LOCKED_STATUS_FAILURE,
  payload: error,
});
