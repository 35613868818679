import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from './TableComponent'; // Import the TableComponent

import {
  fetchLearnerProfileData,
  fetchSearchSuggestions,
  addUserCourse
} from '../../redux/actions/learnerProfileActions';

const LearnerProfile = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const { data, searchSuggestions, loading, error } = useSelector(state => state.learnerProfile);

  useEffect(() => {
    dispatch(fetchLearnerProfileData());
  }, [dispatch]);

  const handleSearchInput = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query.length > 2) {
      dispatch(fetchSearchSuggestions(query));
    }
  };

  const handleAddCourse = (courseData) => {
    dispatch(addUserCourse(courseData));
  };

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Assuming 'data' contains an array of courses
  const courses = data?.courses || [];

  return (
    <div className="learner-profile">
      <TableComponent courses={courses} onAddCourseClick={() => {/* logic to open modal or another action */}} />
    </div>
  );
};

export default LearnerProfile;
