const initialState = {
  data: [], // Use an empty array instead of null
  searchSuggestions: [],
  courses: [], // Note: You have both `data` and `courses`, ensure this is intentional and correctly used
  error: null,
  showAddCourseForm: false,
};

const learnerProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_LEARNER_PROFILE_DATA':
      // Start loading
      return { ...state, loading: true };

    case 'FETCH_LEARNER_PROFILE_DATA_SUCCESS':
      // Load courses successfully
      return { ...state, loading: false, data: action.payload };

    case 'FETCH_LEARNER_PROFILE_DATA_FAILURE':
      // Error loading courses
      return { ...state, loading: false, error: action.payload };

    case 'FETCH_SEARCH_SUGGESTIONS':
      // Start searching
      return { ...state, loading: true };

    case 'FETCH_SEARCH_SUGGESTIONS_SUCCESS':
      // Search completed successfully
      return { ...state, loading: false, searchSuggestions: action.payload };

    case 'FETCH_SEARCH_SUGGESTIONS_FAILURE':
      // Error in search
      return { ...state, loading: false, error: action.payload };

    case 'SHOW_ADD_COURSE_FORM':
      // Show the form to add a course
      return { ...state, showAddCourseForm: true };

    case 'HIDE_ADD_COURSE_FORM':
      // Hide the form to add a course
      return { ...state, showAddCourseForm: false };

    case 'ADD_USER_COURSE':
      // Start adding a new course
      return { ...state, loading: true };

    case 'ADD_USER_COURSE_SUCCESS':
      // Successfully added a course
      const courseExists = state.courses.some(course => course.id === action.payload.id);
      return {
        ...state,
        loading: false,
        courses: courseExists ? state.courses : [...state.courses, action.payload],
      };

    case 'ADD_USER_COURSE_FAILURE':
      // Error adding a course
      return { ...state, loading: false, error: action.payload };

    default:
      // Return the state unchanged in case of no relevant action types
      return state;
  }
};

export default learnerProfileReducer;

