import { all } from 'redux-saga/effects';
import { watchAuthSaga } from './authSagas';
import chatSaga from './chatSagas';
import learnerProfileSaga from './learnerProfileSaga';
import learningScheduleSaga from './learningScheduleSaga';
import flashcardSaga from './flashcardSagas'


// ... other sagas

export default function* rootSaga() {
  try {
    yield all([
      watchAuthSaga(),
      chatSaga(),
      learnerProfileSaga(),
      learningScheduleSaga(),
      flashcardSaga(),
    ]);
  } catch (error) {
    console.error("rootSaga error:", error);
  }
}
