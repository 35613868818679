// Notepad.js
import React, { useEffect, useRef } from 'react';
import {
  BlockNoteView,
  useBlockNote,
  FormattingToolbarPositioner,
  SlashMenuPositioner,
} from '@blocknote/react';
import '@blocknote/react/style.css';
import { Box, useTheme, IconButton, Flex } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { toggleNotepad } from '../../redux/actions/notepadActions';
import { useDispatch } from 'react-redux';

function Notepad() {
  const theme = useTheme();
  const bgColor = 'lightGrayishWhite'; // Use the light background
  const textColor = 'darkGray.500'; // For text color
  const dispatch = useDispatch();
  const editor = useBlockNote({
    defaultStyles: false,
  });

  // Ref for the content container
  const contentContainerRef = useRef();

  const iconColor = 'primary.500';

  const handleToggleNotepad = () => {
    dispatch(toggleNotepad());
  };

  useEffect(() => {
    const blockNoteRoot = document.querySelector('.bn-editor');
    if (blockNoteRoot) {
      // Set custom properties for BlockNote styles
      blockNoteRoot.style.setProperty(
        '--bn-colors-editor-background',
        theme.colors.backgroundWhite
      );
      blockNoteRoot.style.setProperty(
        '--bn-colors-editor-text',
        theme.colors.darkGray['500']
      );
      blockNoteRoot.style.setProperty(
        '--bn-colors-menu-background',
        theme.colors.backgroundWhite
      );
      blockNoteRoot.style.setProperty(
        '--bn-colors-menu-text',
        theme.colors.darkGray['500']
      );
      blockNoteRoot.style.setProperty(
        '--bn-colors-primary',
        theme.colors.primary['500']
      );
      blockNoteRoot.style.paddingInline = '20px';
    }
  }, [theme]);

  return (
    <Box
      h="100vh"
      p={0}
      bg={bgColor}
      color={textColor}
      borderLeft="1px solid"
      borderColor="lightGray.500"
      zIndex="overlay"
    >
      {/* Sticky header with the Close icon */}
      <Flex
        justify="flex-end"
        align="center"
        height="60px"
        bg={bgColor}
        zIndex={10}
        position="sticky"
        top="0"
      >
        <IconButton
          icon={<ChevronRightIcon />}
          aria-label="Toggle Notepad"
          variant="ghost"
          color={iconColor}
          fontSize="35px"
          opacity="0.5"
          onClick={handleToggleNotepad}
          _hover={{ bg: 'lightPeach.500' }}
        />
      </Flex>

      {/* Scrollable notepad content area */}
      <Box
        ref={contentContainerRef}
        h={`calc(100vh - 60px)`}
        overflowY="auto"
        overscrollBehavior="none"
        bg="backgroundWhite" // Set the background of the editor area
      >
        <BlockNoteView editor={editor} theme="light">
          <FormattingToolbarPositioner editor={editor} />
          <SlashMenuPositioner editor={editor} />
        </BlockNoteView>
      </Box>
    </Box>
  );
}

export default Notepad;
